import { config } from "./config";
import {$name, gti, trimObject} from './helpers';
import {fireCustomEvent, addReadyEvent} from './events';
import {ajaxSniffer} from "./ajax_sniffer";
import * as ut from "./ut";
import {push} from "./datalayer";
import {DCMActivity} from "./pixels";
import {GAEvent} from "./ga";
import {readCookie} from "./storage";
import {preload} from "./load_assets";
import {$qs} from "./dom";

//story: https://actnow.edf.org/a/please-share-your-story
//advocacy: https://actnow.edfaction.org/a/dev-only-test-edf-action-advocacy
//petition: https://actnow.edfaction.org/a/edfaction-test-env-test-form
//signup: https://actnow.edfaction.org/a/join-edf-actions-action-team-v1

//NOTE: EA will be sending in events to GA4 via gtag event commands, mostly under event `FormEvent`.

let trackable_callbacks = ['postRender', 'onSubmit'],
    callbacks = trackable_callbacks.concat(['alterErrors', 'alterFill', 'alterFormDefinition', 'alterPost', 'alterRequireValid', 'preSegue', 'postContributionAmountChanged', 'postPaymentMethodChanged', 'postFill', 'segue']);

let ngp_form = null,
    form_rendered = false,
    is_multi_step = false,
    tag_assets_loaded = false,
    initialized = false;

export var everyaction_form_meta = {};

function actionConfirmation(data){
    let id = data.response.submissionId; //corresponds to Snowpipe FOREIGN_SUBMIT_LOG
    let formId = ngp_form.formId;

    if (config.isDev){
        console.log(data);
    }

    ut.setPage('ea_action_id', id);

    GAEvent('action', {
        ec: 'everyaction',
        el: id,
        at: ngp_form.type,
        af: formId+': '+ngp_form.shortCode
    });

    dataLayerPush('completedAllActions'); //needed for GTM!!! to separate things from event action intended for payloads
    dataLayerPush('action', {response: {...data.response}});

    let dcm_obj = {
        u2: id,
        u4: ngp_form.formId,
        u5: ngp_form.type
    };

    DCMActivity('Actio0', 'Advoc0', dcm_obj);

    DCMActivity({
        advertiser_id: 5624628,
        type: 'actio0',
        category: 'advoc0',
        fields: dcm_obj
    });
}

function dataLayerPush(action, data){
    if (!ngp_form){
        return console.error('ngp_form is null');
    }

    let formId = ngp_form.formId,
        for_ut = trackable_callbacks.indexOf(action) > -1 || action === 'action';

    push({
        ut: for_ut,
        pivot_to_event_for_ut: action !== 'action',
        event_source: 'everyaction',
        event: action,
        //...ngp_form,
        ...everyaction_form_meta,

        action_id: formId, //included to standardize dataLayer for combined GTM tags
        p2a_campaign_id: formId, //in service to GTM tags, likely redundant with action_id:
        // https://tagmanager.google.com/#/container/accounts/239880/containers/554976/workspaces/1000411/tags
        // https://tagmanager.google.com/#/container/accounts/239880/containers/554976/workspaces/1000411/tags
        // https://tagmanager.google.com/#/container/accounts/239880/containers/554976/workspaces/1000411/tags
        action_code: ngp_form.shortCode,
        p2a_short_url: ngp_form.shortCode,

        ea_form_id: formId,
        ea_form_type: ngp_form.type.toLowerCase(),

        form_id: formId,
        form_type: ngp_form.type,
        form_code: ngp_form.shortCode,
        form_name: ngp_form.name,
        form_title: ngp_form.title,
        tenant_name: ngp_form.tenantDisplayName,
        form_layout: ngp_form.metadata.layoutStyle,

        ...(data || {})
    });
}

function sniffOutCopy(){
    let email_row_class = 'label.EmailAddress',
        email_row = $qs(email_row_class),
        phone_row_class = 'label.MobilePhone',
        phone_row = $qs(phone_row_class),
        sms_sub_row_class = 'label.SmsSubscribeMobilePhone',
        sms_sub_row = $qs(sms_sub_row_class),
        sms_legal_class = 'div.SmsLegalDisclaimer',
        sms_legal_row = $qs(sms_legal_class);

    if (email_row){
        everyaction_form_meta.email_opt_in_copy = ($qs(email_row_class+' span') || email_row).innerText;
    }

    if (phone_row){
        everyaction_form_meta.phone_opt_in_copy = ($qs(phone_row_class+' span') || phone_row).innerText;
    }

    if (sms_sub_row){
        everyaction_form_meta.sms_opt_in_copy = ($qs(sms_sub_row_class+' span') || sms_sub_row).innerText;
    }

    if (sms_legal_row){
        everyaction_form_meta.sms_legal_copy = ($qs(sms_legal_class+' p') || sms_legal_row).innerText;
    }

    everyaction_form_meta = trimObject(everyaction_form_meta);
}

export function init(){
    if (initialized){
        return;
    }

    initialized = true;

    setTimeout(sniffOutCopy, 2000);

    window.nvtag_callbacks = window.nvtag_callbacks || {};

    let callback_throttles = {};

    callbacks.forEach(function(callback){
        window.nvtag_callbacks[callback] = window.nvtag_callbacks[callback] || [];
        window.nvtag_callbacks[callback].push(function(args){
            if (typeof callback_throttles[callback] !== 'undefined'){
                return;
            }

            callback_throttles[callback] = setTimeout(function(){
                delete callback_throttles[callback];
            }, 750);

            if (config.isTesting){
                console.log(callback);
                console.log(args);
            }

            sniffOutCopy();

            if (trackable_callbacks.indexOf(callback) > -1){
                dataLayerPush(callback);
            }

            if (callback === 'alterPost'){
                // let example = {
                //     "data": {
                //         "Prefix": "Mr.",
                //         "FirstName": "Brian",
                //         "LastName": "Chumney",
                //         "AddressLine1": "5321 Virginia Way",
                //         "PostalCode": "95822",
                //         "City": "Sacramento",
                //         "StateProvince": "CA",
                //         "EmailAddress": "brian.chumney@gmail.com",
                //         "MobilePhoneCountryCode": "us",
                //         "MobilePhoneInternationalDialingPrefix": "1",
                //         "MobilePhone": "5614275175",
                //         "FormVersion": "7/25/2023 6:07:57 PM|8/22/2023 12:56:07 PM",
                //         "type": "AdvocacyForm",
                //         "FormSessionId": "638b0ae6-fd7e-4c61-86d7-7ba2fa15c4a8",
                //         "ClientSubmissionId": "0baf6fe2-e983-41c4-a941-4536f4e05c33",
                //         "BrowserName": "chrome",
                //         "DeviceType": "desktop",
                //         "actionProfileId": "Jvi$YmrNhVnN_umOXuEarRKI",
                //         "autoCreateAccount": "true",
                //         "fastActionSessionId": "SOgt6pmPUFHcsm$hRwh2FViLH6yM",
                //         "endpoint": "https://advocator.ngpvan.com/https%3A%2F%2Fsecure.everyaction.com%2Fv1%2FForms%2F4ShkKyN9lECDohtbK1ctZA2/ngpForm?formDefToken=85d2a492440460653efd915b4d12c801",
                //         "XFromApp": "ActionTag",
                //         "ActionTagBaseUrl": "https://static.everyaction.com/ea-actiontag/"
                //     },
                //     "url": "https://fastaction.ngpvan.com/api/v1/forms/",
                //     "form_id": "",
                //     "form_definition": {},
                //     "endpoint": "https://advocator.ngpvan.com/https%3A%2F%2Fsecure.everyaction.com%2Fv1%2FForms%2F4ShkKyN9lECDohtbK1ctZA2/ngpForm?formDefToken=85d2a492440460653efd915b4d12c801"
                // }

                if (args && typeof args === 'object' && args.data){
                    ut.setUser({
                        email: args.data.EmailAddress || null,
                        ea_profile_id: args.data.actionProfileId || null
                    });

                    if (args.endpoint.indexOf('/ngpForm') > -1){
                        //profile
                    }

                    if (args.endpoint.indexOf('/targetGroups') > -1){
                        //target
                    }
                }

                //set hidden fields
                let utm_id = ut.getSess('utm_id');
                if (utm_id){
                    args.data.oa_utm_id = utm_id;
                }

                //CAUTION: character limit of 150
                args.data.oa_data_packer = JSON.stringify({
                    u: ut.getUser('edf_uuid') || '',
                    p: ut.getPage('id'),
                    s: ut.getSess('id')
                });

                let variant_id = ut.getUser('variant_id');
                if (variant_id){
                    args.data.oa_data_packer2 = JSON.stringify({
                        v: variant_id
                    });
                }
            }
            else if (callback === 'postRender'){
                if (args.thank){
                    //end confirmation page
                }
                else {
                    if (!form_rendered){
                        form_rendered = true;

                        push({
                            event: 'timing',
                            utc: 'everyaction_form',
                            utl: ngp_form.formId+': '+ngp_form.shortCode,
                            utt: gti(config.pageStartTime)
                        });
                    }
                }
            }
            else if (callback === 'preSegue'){
                if (args.confirmation && args.confirmation.confirmationActionType === "DisplayContent"){
                    //end confirmation page
                }

                //not guaranteed lengths
                // args.postVals.Messages[0] //body sent
                // args.postVals.Subjects[0] //subject sent

                if (args.response && args.response.submissionId){
                    actionConfirmation({...args});
                }
            }
        });
    });

    let ProfileDatabagId = readCookie('ProfileDatabagId');
    if (ProfileDatabagId){
        ut.setUser('ea_profile_id', ProfileDatabagId);
    }

    ajaxSniffer(function(err, that, url, response){
        function parse(){
            if (typeof response === 'string'){
                try {
                    response = JSON.parse(response);
                } catch(e){
                    response = {};
                }
            }
        }

        if (config.isTesting && (url.indexOf('everyaction') > -1 || url.indexOf('ngp') > -1 || url.indexOf(config.HN) > -1)){
            console.log(url);
            console.log(response);
        }

        //https://docs.everyaction.com/reference/enhanced-ecommerce-tracking-in-google-tag-manager
        //https://secure.everyaction.com/WkkeP7XbVUeHnSpqorU--Q2
        if (url.indexOf('/Forms'+config.PN) > -1){
            parse();

            if ((response.ClientSubmissionId || (response.resultCode+'').toLowerCase() === 'success')
                || (response.response && response.response.submissionId)
            ){
                if (config.PN === '/WkkeP7XbVUeHnSpqorU--Q2'){
                    $EDF.clearCookiesAndReload();
                }
            }
        }

        if (url.indexOf('https://profile.ngpvan.com/') === 0 && url.indexOf('/data/') > -1){
            parse();

            //https://profile.ngpvan.com/v2/data/Jvi$YmrNhVnN_umOXuEarRKI/nvtag
            let profile_id = url.split('/data/')[1].split('/nvtag')[0];
            ut.setUser('ea_profile_id', profile_id);

            // {"Prefix":"Mr.","AddressLine1":"5321 Virginia Way","PostalCode":"95822","City":"Sacramento","StateProvince":"CA","MobilePhoneCountryCode":"us","MobilePhone":"5614275175","AddressLine2":"","email":"brian.chumney@gmail.com","firstName":"Brian","lastName":"Chumney"}

            if (response.email){
                ut.setUser('email', response.email);
            }
        }

        if ((url.indexOf('https://advocator.ngpvan.com/') === 0 && url.indexOf('/ngpForm') > -1)
            || (url.indexOf('https://secure.everyaction.com/v1/Forms/') === 0)
        ){
            parse();

            if (response.formId && !ngp_form){
                ut.setPage('nid', response.formId);

                ngp_form = {...response};
                window.edf_ngp_form = {...response};
                fireCustomEvent('ngpFormReady', {...response});

                dataLayerPush('everyaction_pageview');

                addReadyEvent(function(){
                    let body_classes_to_add = ['form-'+response.formId, 'type-'+response.type.toLowerCase(), 'fast-action-'+response.fastAction, 'status-'+response.status.toLowerCase(), 'short-code-'+response.shortCode, 'form-steps-'+(response.steps||[]).join('-').toLowerCase()];

                    if (response.metadata){
                        if (response.metadata.layoutStyle === 'multistep'){
                            is_multi_step = true;
                        }

                        body_classes_to_add = body_classes_to_add.concat(['layout-style-'+response.metadata.layoutStyle, 'layout-columns-'+response.metadata.columns]);
                    }

                    requestAnimationFrame(function(){
                        $name('body').classList.add(...body_classes_to_add);
                    });
                });
            }
        }
    });
}

function loadEATagAssets(){
    if (!tag_assets_loaded){
        tag_assets_loaded = true;

        let base = 'https://static.everyaction.com/ea-actiontag/at.';
        preload(base+'min.css');
        loadScript(base+'js');
    }
}
export function appendEATag(target, formTag){
    loadEATagAssets();

    target.innerHTML = `<div class="ngp-form"
                 data-form-url="https://secure.everyaction.com/v1/Forms/${formTag}"
                 data-fastaction-endpoint="https://fastaction.ngpvan.com"
                 data-inline-errors="true"
                 data-fastaction-nologin="true"
                 data-databag-endpoint="https://profile.ngpvan.com"
                 data-databag="everybody"
                 data-mobile-autofocus="false">
            </div>`;

    init();
}