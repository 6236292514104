//https://css-tricks.com/paint-timing-api/
export function getFirstPaint() {
    let perf = 'performance',
        timing = 'timing',
        firstPaintTime = 0,
        performance = !!window[perf];

    if (performance && typeof window[perf].getEntriesByType === 'function') {
        let paint = window[perf].getEntriesByType('paint');

        if (Array.isArray(paint) && paint.length && paint[0].startTime) {
            firstPaintTime = paint[0].startTime;
        }
    }
    else if (performance && window[perf][timing] && typeof window[perf][timing].msFirstPaint === 'number') {
        firstPaintTime = window[perf][timing].msFirstPaint - window[perf][timing].navigationStart;
    }

    firstPaintTime = firstPaintTime < 0 ? 0 : firstPaintTime;

    return Math.round(firstPaintTime);
}