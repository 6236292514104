import {addEvent, addReadyEvent, addUnLoadEvent} from "./events";
import {forAll} from "./dom";
import {gti} from "./helpers";
import {GAEvent} from "./ga";

let players = {};

export function trackHTML5Player(type, category){
    addReadyEvent(function(){
        forAll(type, function(player){
            let src = player.currentSrc;
            if (!src || src.toLowerCase().indexOf('logo') > -1){
                return;
            }

            src = src.split('/');
            src = src[src.length-1].split('?')[0];

            //https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
            addEvent(player, ['loadeddata', 'play', 'pause', 'seeking', 'seeked', 'waiting', 'ended'/*, 'timeupdate'*/], function(e){
                let duration =  e.target.duration,
                    current_time = e.target.currentTime || 0;

                if (!players[src]){
                    players[src] = {
                        target: e.target,
                        actions: [],
                        total_time: 0,
                        current_time: 0,
                        loops: 0,
                        duration: duration
                    }
                }

                players[src].actions.push({
                    type: e.type,
                    gti: gti(),
                    current_time: current_time
                });

                if (players[src].unloading){
                    return;
                }

                players[src].current_time = current_time;

                if (e.type === 'seeking'){
                    players[src].loops++;
                }

                if (e.type === 'pause' || e.type === 'ended' || e.type === 'seeking'){
                    players[src].total_time = parseInt(current_time+(duration*players[src].loops));
                }
            });
        });

        addUnLoadEvent(function(){
            for (let id in players){
                let player = players[id];

                if (player.target && player.target.paused === false){
                    player.target.pause();
                    player.unloading = true;
                    player.total_time = parseInt(player.target.currentTime+(player.duration*player.loops));
                }

                GAEvent(type+'_time', {
                    ec: category,
                    el: JSON.stringify({duration: player.duration, loops: player.loops, total_time: player.total_time}), //TODO set this as props for hits, but move stringify into ga for labels....when transitioning to ga4 as primary server over ga customTask
                    ev: player.total_time,
                    at: type,
                    af: id
                });
            }
        });
    });
}

export {
    players
}