import {$qs} from "./dom";

export var resources = {};
export var resourceTypes = {}

// let observer = new PerformanceObserver((list) => {
//     for (let entry of list.getEntries()) {
//
//     }
// });
//
// //https://developer.mozilla.org/en-US/docs/Web/API/PerformanceEntry/entryType
// observer.observe({entryTypes: ['longtask', 'paint', 'resource', 'element']});

//window.performance.getEntriesByType('resource')

// 103
// :
// PerformanceResourceTiming
// connectEnd
//     :
//     5957
// connectStart
//     :
//     5957
// decodedBodySize
//     :
//     0
// domainLookupEnd
//     :
//     5957
// domainLookupStart
//     :
//     5957
// duration
//     :
//     175.09999999403954
// encodedBodySize
//     :
//     0
// entryType
//     :
//     "resource"
// fetchStart
//     :
//     5957
// initiatorType
//     :
//     "beacon"
// name
//     :
//     "https://test.utility.edf.org/apps/hit"
// nextHopProtocol
//     :
//     "h2"
// redirectEnd
//     :
//     0
// redirectStart
//     :
//     0
// renderBlockingStatus
//     :
//     "non-blocking"
// requestStart
//     :
//     5958.299999982119
// responseEnd
//     :
//     6132.0999999940395
// responseStart
//     :
//     6131.5999999940395
// responseStatus
//     :
//     0
// secureConnectionStart
//     :
//     5957
// serverTiming
//     :
//     Array(3)
// 0
// :
// PerformanceServerTiming {name: 'status_code', duration: 204, description: ''}
// 1
// :
// PerformanceServerTiming {name: 'req_id', duration: 89, description: '481e18a4-2d31-4f8c-b96a-e3795ad638b3'}
// 2
// :
// PerformanceServerTiming {name: 'uuid', duration: 0, description: '448ac460-76e2-4b42-85f2-9227f65f670c'}
// length
//     :
//     3
//         [[Prototype]]
// :
// Array(0)
// startTime
//     :
//     5957
// transferSize
//     :
//     300
// workerStart
//     :
//     0

export function getNetworkReqs(){
    if (!window.performance || !performance.getEntriesByType){
        return;
    }

    let requests = performance.getEntriesByType('resource');

    if (!Array.isArray(requests)){
        return null;
    }

    return requests;
}

export function listFonts() {
    let fonts = [];

    for (let node of $qs('*')) {
        if (!node.style) continue

        for (let pseudo of ['', ':before', ':after']) {
            let fontFamily = getComputedStyle(node, pseudo).fontFamily;
            fonts = fonts.concat(fontFamily.split(/\n*,\n*/g));
        }
    }

    // Remove duplicate elements from fonts array and remove the surrounding quotes around elements
    return [...new Set(fonts)]
        .map(font => font.replace(/^\s*['"]([^'"]*)['"]\s*$/, '$1').trim());
}