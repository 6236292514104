import { config } from "./config";
import {isFunction, gti, removeEPs, stripNonUTF, trimObject, $id, copyObj, trimProtocol, $name, qrySep, gup, stripParam, ensureItemIsArray, numberSniffer} from "./helpers";
import { namespace, push } from "./datalayer";
import {acceptsCookies} from "./storage";
import { loadScript } from "./load_assets";
import * as ut from "./ut";
import {getContactFlags} from "./adestra_auth";
import {$qs, appendHTML, getHeadline, getMetaTags} from "./dom";
import {forceChange, addEvent} from "./events";
import {isMacSafari, supportsBeacon} from "./client_detection";
import {isUrl} from "./validators";
import {track} from "./track";

const event_property_count_threshold = 20;

let GA4Init = false,
    ga4_events = [],
    ga4_events_count = 0,
    ga4_load_attempts = 0,
    page_view_has_fired = false,
    last_ga_config_obj = {},
    last_ga_user_obj = {},
    ga_config_obj = {},
    ga_user_obj = {},
    ga_consent_obj = {},
    params_to_keep_for_ga = config.utm_parameters.concat(config.ub_parameters),
    props_only_needed_once = ['tracker_version','ttfb','inp','cls','fid','lcp','fcp','connection_type','network_category','network_domain','network_provider','nav_type','custom_channel','attribution_type','transport_type','cookie_flags'],
    props_sent_during_view = [],
    props_to_exclude = ['no_category', 'dno', 'x', 'ga4', 'ga', 'ua', 'bypass_ua', 'bypass_ga4', 'lytics', 'ly'],
    not_found;

function getPropertiesCount(obj){
    let count = Object.keys(obj).length;
    ['page_location', 'page_referrer', 'page_path', 'page_title'].forEach(function(prop){
        if (obj[prop]){
            count--;
        }
    });
    return count;
}

export function grantStorage(){
    _gtag_consent("granted", true);
}

//https://developers.google.com/tag-platform/security/guides/consent#upgrade-consent-v2
export function _gtag_consent(obj, update){
    if (typeof obj === 'string' && ['granted', 'denied'].indexOf(obj) > -1){

    }
    else if (!obj || typeof obj !== 'object'){
        return;
    }

    let props = ['ad_storage', 'analytics_storage', 'functionality_storage', 'personalization_storage', 'security_storage', 'ad_user_data'];

    if (typeof obj === 'string'){
        let preference = obj;
        obj = {};

        props.forEach(function(prop){
            obj[prop] = preference;
        });
    }
    else {
        for (let prop in obj){
            if (props.indexOf(prop) === -1){
                delete obj[prop];
            }
        }
    }

    obj = trimObject(obj);

    ga_consent_obj = {
        ...ga_consent_obj,
        ...obj
    };

    GA4Ready(function(){
        window.gtag("consent", update ? 'update' : 'default', ga_consent_obj);
    });
}

export function _gtag_set(obj){
    obj = trimObject(obj || {});

    GA4Ready('config', function(){
        //If you configure multiple properties on a single page, it may be more efficient to use the set command.
        //https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values

        let user_properties = ['adestra_contact_id', 'classy_member_id', 'temp_id', 'ga_client_id', 'traffic_type', 'drupal_uid', 'personas', 'contact_flags', 'lytics_uid'],
            attribution_type = null,
            referrer = removeEPs(config.docReferrer, params_to_keep_for_ga).toLowerCase(),
            user_id = ut.getUser(config.isUtility || config.isInsider ? 'drupal_uid' : 'adestra_contact_id') || null;

        ga_user_obj = {
            user_id: user_id,
            lytics_uid: ut.getUser('lytics_uid') || null,
            adestra_contact_id: ut.getUser('adestra_contact_id') || null,
            classy_member_id: ut.getUser('classy_member_id') || null,
            temp_id: ut.getUser('edf_uuid') || null, //NOTE: giving up temp_id to pipe in edf_uuid
            drupal_uid: ut.getUser('drupal_uid') || null,
            ga_client_id: ut.getUser('ga_client_id') || null,
            contact_flags: getContactFlags() || null,
            personas: ut.getUser('personas') || null,
            traffic_type: ut.getSess('internal') ? 'internal' : null
        };

        for (let prop in obj){
            if (user_properties.indexOf(prop) > -1){
                ga_user_obj[prop] = obj[prop];
                delete obj[prop];
            }
        }

        ga_user_obj = trimObject(ga_user_obj);

        if (Object.keys(ga_user_obj).length && JSON.stringify(last_ga_user_obj) !== JSON.stringify(ga_user_obj)){
            window.gtag('set', 'user_properties', ga_user_obj);
            last_ga_user_obj = {...ga_user_obj};
        }

        if (ut.getSess('webteam_traffic')){
            attribution_type = 'webteam';
        }
        else if (ut.getSess('email_traffic')){
            attribution_type = 'email';
        }
        else if (ut.getSess('marketing_traffic')){
            attribution_type = 'marketing';
        }

        let url_builder_ = 'url_builder_';
        let default_config_obj = {
            //TODO come up with better `send_page_view` solution given sites like MM proxy send to UA via GA4. If set to false, gtag won't auto manage the UA version.
            send_page_view: false,  //https://developers.google.com/analytics/devguides/collection/gtagjs/pages
            transport_type: supportsBeacon() ? 'beacon' : 'image',
            tracker_version: config.V,
            page_title: getTitle(),
            page_location: getUrl(),
            page_path: getPath(),
            page_referrer: referrer,
            //screen_resolution: window.screen.width+'x'+window.screen.height,
            nav_type: ut.getPage('nav_type_value') || null,
            sess_id: ut.getSess('id') || null, //do not label as session_id because that will overwrite ga's session id
            page_id: ut.getPage('id') || null,
            last_page_id: ut.getPage('last_page_id') || null,
            user_id: user_id, //user id defaults to drupal id for utility, and adestra contact id elsewhere
            utm_id: ut.getSess('utm_id') || null,
            campaign_id: ut.getSess('utm_id') || null,
            campaign_source: ut.getSess('utm_source') || null,
            campaign_medium: ut.getSess('utm_medium') || null,
            campaign_name: ut.getSess('utm_campaign') || null,
            campaign_content: ut.getSess('utm_content') || null,
            campaign_term: ut.getSess('utm_term') || null,
            custom_channel: get_custom_channel(),
            attribution_type: attribution_type,
            wave_code: ut.getSess('wave_code') || null,
            campaign_code: ut.getSess('internal_source_code') || null,
            [url_builder_+'owner']: ut.getSess(url_builder_+'owner') || null,
            [url_builder_+'tactic']: ut.getSess(url_builder_+'tactic') || null,
            [url_builder_+'target']: ut.getSess(url_builder_+'target') || null,
            [url_builder_+'cta']: ut.getSess(url_builder_+'cta') || null,
            [url_builder_+'funnel']: ut.getSess(url_builder_+'funnel') || null,
            //https://developers.google.com/analytics/devguides/collection/gtagjs/cross-domain
            linker: {
                accept_incoming: true,
                domains: config.ga4_linker_domains
            }
        };

        for (let prop in default_config_obj){
            //lowercase all cam
            if (prop.indexOf('campaign_') === 0 && typeof default_config_obj[prop] === 'string'){
                default_config_obj[prop] = default_config_obj[prop].toLowerCase();
            }
        }

        if (((config.isAdmin || gup('ga4_debug') === 'TRUE') && config.isTesting) || config.isDev){
            default_config_obj.debug_mode = true;
        }

        let content_id = ut.getPage('nid');
        if (content_id && content_id + '' !== '-1') {
            default_config_obj.content_id = parseInt(content_id);
        }

        let meta = getMetaTags(),
            keywords = meta.keywords;

        if (config.main_site && keywords){
            default_config_obj.content_group = keywords;
        }

        //see https://github.com/edf-org/insider/issues/11
        if (config.isInsider && meta.ldjson && Array.isArray(meta.ldjson['@graph']) && meta.ldjson['@graph'].length){
            let graph = meta.ldjson['@graph'][0],
                group = '';

            if (graph.publisher && graph.publisher.name){
                let division = ensureItemIsArray(graph.publisher.name, true).join(',');
                if (division){
                    group += division;
                }
            }

            if (graph.keywords){
                let tags = ensureItemIsArray(graph.keywords, true).join(',');
                if (tags){
                    group += (!!group?':':'')+tags;
                }
            }

            if (group){
                default_config_obj.content_group = group;
            }
        }

        if (config.LN === 'https:'){
            //https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
            // https://developers.google.com/analytics/devguides/collection/ga4/reference/config#cookie_flags
            //https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite

            default_config_obj.cookie_flags = 'SameSite=None;Secure';
        }

        ga_config_obj = trimObject({
            ...default_config_obj,
            ...ga_config_obj,
            ...obj
        });

        if (JSON.stringify(last_ga_config_obj) !== JSON.stringify(ga_config_obj)){
            window.gtag('set', ga_config_obj); //consolidates setting for multiple stream ids at once https://developers.google.com/tag-platform/gtagjs/reference/parameters

            for (let i=0; i<config.ga4_stream.length; i++){
                window.gtag('config', 'G-'+config.ga4_stream[i], ga_config_obj);
            }
        }

        last_ga_config_obj = {...ga_config_obj};
    });
}

export function standardizeLabel(str){
    return (str+'').toLowerCase().trim().replace(/-/g, '').replace(/_/g, '').replace(/ /g, '')
}

export function get_custom_channel(){
    //https://www.optimizesmart.com/understanding-channel-groupings-in-google-analytics-4/
    //https://support.google.com/analytics/answer/9756891?hl=en

    let sess = ut.getSess(),
        channel = 'other',
        medium_str = standardizeLabel(sess.utm_medium),
        source_str = standardizeLabel(sess.utm_source);

    let social_source = new RegExp(/facebook|twitter|linkedin|instagram|ig|fb|tiktok|snapchat|sprout_social/),
        social = new RegExp(/social|socialnetwork|socialmedia|sm/),
        paid = new RegExp(/^(cpc|ppc|paid)$/);

    if (!sess.utm_source || !sess.utm_medium || source_str === 'direct'){
        channel = 'direct';
    }
    else if (medium_str === 'organic'){
        channel = 'organic_search';
    }
    else if (medium_str === 'referral'){
        channel = 'referral';
    }
    else if (medium_str.indexOf('affiliate') > -1){
        channel = 'affiliates';
    }
    else if (medium_str.indexOf('email') > -1 || source_str === 'email'){
        channel = 'email';
    }
    else if (medium_str.indexOf('mail') > -1){
        channel = 'direct_mail';
    }
    else if (paid.test(medium_str) && social_source.test(source_str)){
        channel = 'paid_social';
    }
    else if (social_source.test(source_str) || social.test(medium_str)){
        channel = 'social';
    }
    else if (paid.test(medium_str)){
        channel = 'paid_search';
    }
    else if (source_str === 'youtube' || medium_str === 'video'){
        channel = 'video';
    }
    else {
        //video
        //display
    }

    return channel;
}

//https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
export function get_ecommerce_item(amount, frequency){
    if (!config.isClassy || !config.classy_props.campaign_id){
        return {};
    }

    frequency = frequency || config.classy_props.frequency;

    let data = EDFClassy.hiddenFields.internal_data || $EDF.edf_internal_data || {},
        options = EDFClassy.options.form,
        item_category = 'item_category',
        item = {
            item_name: config.classy_props.campaign_name,
            item_id: config.classy_props.campaign_id,
            price: amount || EDFClassy.getFormData().amount || config.classy_props.amount || 1,
            item_brand: config.classy_props.organization_name,
            item_list_name: config.classy_props.internal_name,
            quantity: 1
        };

    if (frequency){
        item[item_category] = 'frequency:'+frequency;
        item.item_variant = frequency;
    }

    if (data.isc){
        item[item_category+'2'] = 'campaign:'+data.isc;
    }

    if (options.form_owner){
        item[item_category+'3'] = 'owner:'+options.form_owner;
    }

    if (data.wv){
        item[item_category+'4'] = 'wave:'+data.wv;
    }

    if (data.ch){
        item[item_category+'5'] = 'channel:'+data.ch;
    }

    return item;
}

export function load(stream, src){
    if (config.DNT){
        return;
    }

    if (ga4_load_attempts > 2 || typeof stream !== 'string' || !stream){
        return;
    }

    stream = stream.toUpperCase();

    ga4_load_attempts++;

    if (typeof window.gtag !== 'function'){
        loadScript({url: src || 'https://www.googletagmanager.com/gtag/js?id=G-'+stream+'&l='+namespace, defer: true}, function(){
            window.gtag = function(){
                window[namespace].push(arguments);
            }

            window.gtag('js', new Date());
        });

        if (!src){
            setTimeout(function(){
                if (typeof window.gtag !== 'function'){
                    load(stream, config.utility_path+stream+'/_g4.js?l='+namespace);
                }
            }, 5000);
        }
    }
}

export function configure_ga4(){
    //CAUTION: 10 million hits per month per property
    //CAUTION: 500 hits per session         //https://developers.google.com/analytics/devguides/collection/gtagjs/limits-quotas
    //CAUTION: Maximum number of timing events that will be processed 100,000 - 1,000,000 pageviews -> 10,000 timing events
    //CAUTION: network byte size cap: 8092 bytes, i.e. 8092 characters per payload
    //https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings#sampling_considerations

    if (config.ga4_stream.length){
        load(config.ga4_stream[0]);
    }

    if (!acceptsCookies()){
        _gtag_consent("denied");
    }

    if (config.ga4_stream.length){
        let referrer = removeEPs(config.docReferrer, params_to_keep_for_ga).toLowerCase();

        _gtag_set();

        //NOTE: this will not execute for local and send utility hits, even though other GAEvents will....because this is scoped under ga4_stream.lenght check
        GAEvent('page_view', {
            page_location: getUrl(),
            page_path: getPath(),
            page_referrer: referrer,
            action_focus: config.PN
        });

        if (not_found){
            setTimeout(function(){
                GAEvent('exception', {
                    ec: 'page_not_found',
                    el: referrer,
                    at: not_found,
                    af: (config.PN + config.QS).toLowerCase()
                });
            }, 1000);
        }

        GA4Ready(function(){
            window.gtag('get', 'G-'+config.ga4_stream[0], 'client_id', function(cid){
                if (cid){
                    cid += '.'; //NOTE: appending trailing period to prevent GA casting value as double integer
                    ut.setUser('cid', cid);
                    ut.setUser('ga_client_id', cid);
                    _gtag_set({ga_client_id: cid});
                }
            });

            window.gtag('get', 'G-'+config.ga4_stream[0], 'session_id', function(sid){
                if (sid){
                    ut.setSess('ga4_session_id', sid);
                }
            });

            push({event: 'ga4_ready'});
        });
    }
}

export function GA4Ready(event_name, cb){
    if (isFunction(event_name)){
        cb = event_name;
        event_name = null;
    }

    let is_page_view_event = event_name === 'page_view',
        is_config_event = event_name === 'config';

    if (GA4Init || page_view_has_fired){
        return cb();
    }

    let wait = setInterval(function() {
        let time_since_start = gti(config.pageStartTime);

        if (time_since_start > 1000 * 30) {
            clearInterval(wait);
        }
        else if (isFunction(window.gtag)) {
            if (is_config_event){
                clearInterval(wait);
                cb();
            }
            else if (GA4Init || is_page_view_event || time_since_start > 1000 * 10){
                clearInterval(wait);

                if (is_page_view_event){
                    page_view_has_fired = true;
                    cb();
                }
                else {
                    setTimeout(cb, 5);
                }

                GA4Init = true;
            }
        }
    }, 1);
}

export function GAEvent(category, action, label, value) {
    if (config.DNT){
        return;
    }

    let bypass_ut = false,
        bypass_ga4 = false,
        allow_override = true,
        engagement_interaction = 0,
        ga4_properties = {};

    if (action && typeof action === 'object'){
        ga4_properties = copyObj(action);

        ga4_properties.event_action = category;

        for (let prop in ga4_properties){
            if (ga4_properties[prop] === null || typeof ga4_properties[prop] === 'undefined'){
                delete ga4_properties[prop];
            }
            else {
                if (prop === 'event_label' || prop === 'el'){
                    label = ga4_properties[prop];
                }
                else if ((prop === 'event_value' || prop === 'ev' || prop === 'value') && !isNaN(ga4_properties[prop])){
                    value = parseInt(ga4_properties[prop]);
                    ga4_properties.value = value;
                    ga4_properties.event_value = value; //do we need event_value?
                    delete ga4_properties.ev;
                }

                if (prop === 'ec' || prop === 'category'){
                    ga4_properties.event_category = ga4_properties[prop];
                    delete ga4_properties[prop];
                }
                else if (prop === 'ea' || prop === 'action'){
                    ga4_properties.event_action = ga4_properties[prop];
                    delete ga4_properties[prop];
                }
                else if (prop === 'el' || prop === 'label'){
                    ga4_properties.event_label = ga4_properties[prop];
                    delete ga4_properties[prop];
                }
                else if (prop === 'at' || prop === 'type'){
                    ga4_properties.action_type = ga4_properties[prop];
                    delete ga4_properties[prop];
                }
                else if (prop === 'af' || prop === 'focus'){
                    ga4_properties.action_focus = ga4_properties[prop];
                    delete ga4_properties[prop];
                }
                else if (prop === 'ga4'){
                    bypass_ga4 = !ga4_properties[prop];
                }
                else if (prop === 'ut'){
                    bypass_ut = !ga4_properties[prop];
                }
                else if (prop === 'dno'){
                    allow_override = !ga4_properties[prop];
                }
                else if (prop === 'ei' || prop === 'engaged' || prop === 'engagement_interaction'){
                    engagement_interaction = !!ga4_properties[prop];
                    delete ga4_properties[prop];
                }
            }
        }

        category = null;
        action = null;
    }
    else if (typeof category === 'object') {
        action = category.event_action || category.action || category.ea;
        label = category.event_label || category.label || category.el;

        if (('value' in category || 'ev' in category) && !isNaN(category.value || category.ev)) {
            value = category.value || category.ev;
        }

        bypass_ga4 = category.bypass_ga4 || ('ga4' in category && !category.ga4);

        if (category.dno){
            allow_override = false;
        }

        else if (category.ei || category.engaged || category.engagement_interaction){
            engagement_interaction = 1;
        }

        category = category.category || category.ec;
    }
    else {
        ga4_properties = {
            event_category: category,
            //event_action: null, don't include action here since action is separate from properties object
            event_label: label || null
        }
    }

    label = label === null || typeof label === 'undefined' || label === '' ? '(empty)' : label;
    ga4_properties.event_value = typeof value !== 'undefined' && value && !isNaN(parseInt(value)) ? parseInt(value) : null;

    //Recommended events: https://support.google.com/analytics/answer/9267735

    //Event specs/rules: https://support.google.com/analytics/answer/9267744?hl=en&ref_topic=9756175
    let ga4_event_name = stripNonUTF((ga4_properties.event_action || action).toLowerCase().replace(/ /g, '_').replace(/-/g, '_')),
        event_obj = Object.assign({
            event_category: stripNonUTF(ga4_properties.event_category || category || 'no_category').toLowerCase(),
            event_label: stripNonUTF(ga4_properties.event_label || label || ''),
            event_value: ga4_properties.event_value || 0,
            timestamp: new Date().toISOString()
        }, ga4_properties);

    let ga4_category = (event_obj.event_category||'no_category').toLowerCase().trim();

    delete event_obj.event_action;

    if (allow_override){
        if (ga4_event_name === 'closed' || ga4_event_name === 'closes'){
            ga4_event_name = 'close';
        }
        else if (ga4_event_name === 'displayed' || ga4_event_name === 'displays'){
            ga4_event_name = 'display';
        }
        else if (ga4_event_name === 'opened' || ga4_event_name === 'opens'){
            ga4_event_name = 'open';
        }
        else if (ga4_event_name === 'clicked' || ga4_event_name === 'clicks'){
            ga4_event_name = 'click';
        }
        else if (ga4_event_name === 'btn_clicked'){
            ga4_event_name = 'click';
            event_obj.action_focus = 'button';
        }
        else if (ga4_event_name === 'submitted' || ga4_event_name === 'submits' || ga4_event_name === 'submission'){
            ga4_event_name = 'submit';
            engagement_interaction = 1;
        }
        else if (ga4_event_name === 'signup'){
            ga4_event_name = 'sign_up'; //recommended: https://support.google.com/analytics/answer/9267735
        }
        else if (ga4_event_name === 'auth'){
            ga4_event_name = 'login'; //recommended: https://support.google.com/analytics/answer/9267735
        }

        if (ga4_category ==='outbound links' || ga4_category === 'file click'){
            ga4_event_name = 'click';
            event_obj.action_type = ga4_category ==='outbound links' ? 'outbound' : 'file';
            event_obj.action_focus = event_obj.event_label;

            delete event_obj.event_category;
            delete event_obj.event_action;
            delete event_obj.event_label;
        }

        if (ga4_category === 'show/hide'){
            ga4_event_name = 'click';
            event_obj.event_category = 'collapsible_link';
            event_obj.action_type = event_obj.event_action;
            event_obj.action_focus = event_obj.event_label;

            delete event_obj.event_action;
            delete event_obj.event_label;
        }
        else if (ga4_category.indexOf('overlay') > -1 && ga4_event_name === 'debug'){
            //allowing a sample to hit GA
            if (Math.random() < .1){
                return GAEvent('exception', {
                    description: event_obj.event_label
                });
            }

            bypass_ga4 = true;
            //TODO send to datalayer?
        }
        else if (ga4_category.indexOf('overlay') > -1 || ga4_category.indexOf('pushdown') > -1 || ga4_category.indexOf('sticky footer') > -1){
            if (ga4_event_name === 'minimized' || ga4_event_name === 'maximized'){
                event_obj.action_type = ga4_event_name.replace('d', '');
                ga4_event_name = 'click';
            }

            if (ga4_event_name.indexOf('display_') > -1){
                event_obj.action_type = ga4_event_name.replace('display_', '').replace('(', '').replace(')', '');
                ga4_event_name = 'display';
            }

            event_obj.action_focus = event_obj.event_label;
            delete event_obj.event_label;
        }
        else if (ga4_category === 'drupal form'){
            event_obj.action_type = ga4_category;
            event_obj.action_focus = event_obj.event_label;
            delete event_obj.event_category;
            delete event_obj.event_label;

            if (ga4_event_name.indexOf('blocked') > -1){
                event_obj.event_label = 'blocked';
            }

            ga4_event_name = 'submit';
            engagement_interaction = 1;
        }
        else if (ga4_category.indexOf('facebook') > -1 || ga4_category.indexOf('twitter') > -1 || ga4_category.indexOf('linkedin') > -1){
            event_obj.method = ga4_category.indexOf('facebook') > -1 ? 'Facebook' : (ga4_category.indexOf('twitter') > -1 ? 'Twitter' : 'LinkedIn');

            ga4_event_name = 'share'; //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share

            delete event_obj.event_category;
            delete event_obj.event_label;

            engagement_interaction = 1;
        }
        else if (ga4_category === 'social share' && ga4_event_name === 'click'){
            ga4_event_name = 'share';
            event_obj.method = event_obj.event_label;

            delete event_obj.event_category;
            delete event_obj.event_label;

            engagement_interaction = 1;
        }
        else if (ga4_category === 'edaf' && (ga4_event_name === 'facebook' || ga4_event_name === 'twitter')){
            if (event_obj.event_label.toLowerCase().indexOf('cancel') === -1){ //could be just a click too
                event_obj.method = ga4_event_name;

                if (event_obj.event_label.indexOf(': ') > -1){
                    event_obj.item_id = event_obj.event_label.split(': ')[1];
                }

                delete event_obj.event_category;
                delete event_obj.event_label;
            }
        }
        else if (ga4_category === 'mailchimp' || (ga4_category === 'subscriptions' && ga4_event_name.indexOf('click') > -1)){
            event_obj.event_category = 'mailchimp';

            if (ga4_category === 'mailchimp'){
                event_obj.action_type = ga4_event_name;
            }
            else {
                event_obj.action_type = 'EDF Voices';
                delete event_obj.event_label
            }

            ga4_event_name = 'mailchimp_signup';

            engagement_interaction = 1;
        }
        else if (ga4_event_name === 'click' && ['accordions', 'tabs'].indexOf(ga4_category) > -1){
            event_obj.action_type = ga4_category;
            event_obj.action_focus = event_obj.event_label || null;

            delete event_obj.event_category;
            delete event_obj.event_label;
        }
    }

    if (ga4_event_name === 'display'){
        let counter;

        if (ga4_category.indexOf('overlay') > -1){
            counter = ut.incSess('overlay_displays');
        }
        else if (ga4_category.indexOf('sticky footer') > -1){
            counter = ut.incSess('sticky_footer_displays');
        }
        else if (ga4_category.indexOf('pushdown') > -1){
            counter = ut.incSess('pushdown_displays');
        }
        else if (ga4_category === 'ss'){
            counter = ut.incSess('slideshow_displays');
        }

        if (counter){
            event_obj.event_counter = counter;
        }
    }

    if (ga4_category === 'ss'){
        let full_rotation_flag = 'slideshow_full_rotation';

        event_obj.action_type = 'slideshow';
        event_obj.action_focus = event_obj.event_label;

        delete event_obj.event_category;
        delete event_obj.event_label;

        if ((ga4_event_name === 'display' || ga4_event_name === 'full_rotation') && ut.getPage(full_rotation_flag)){ //CAUTION: this is a page-level block, though likely no pages with two auto-playing slideshows
            return; //no sense triggering these display events over and ever
        }

        if (ga4_event_name === 'full_rotation'){
            ut.setPage(full_rotation_flag, true);
        }

        if (ga4_event_name.indexOf('click') > -1){
            engagement_interaction = 1;
        }
    }

    if (ga4_event_name === 'share'){
        event_obj.content_type = event_obj.content_type || 'page';
        event_obj.item_id = event_obj.item_id || ut.getPage('nid') || null;
    }

    if (ga4_category.indexOf('debugging engagement') === 0){
        return; //not sending for cleanliness
    }

    if (ga4_category.indexOf('convio event') > -1 && ga4_event_name === 'gtm_action_confirmation'){
        return; //not needed in ga4
    }

    if (!bypass_ga4){
        ga4_events_count++;

        ut.incPage('ga4_events');
        ut.incSess('ga4_events');
    }

    event_obj.event_index = ga4_events_count;

    if (!bypass_ut){
        push(Object.assign({
            event: 'event',
            m: 'ga4',
            ea: ga4_event_name,
            lytics: false,
            allow_override: ga4_properties.allow_ut_override || event_obj.action_type === 'cookie_consent'
        }, event_obj));
    }

    if (event_obj.lytics !== false && event_obj.ly !== false && (event_obj.conversion || config.conversion_events.includes(ga4_event_name))){
        track({
            ga4: false,
            lytics: true,
            event_name: ga4_event_name,
            ...event_obj
        });
    }

    if (bypass_ga4){
        return;
    }

    for (let prop in ga_config_obj){
        if (['linker', 'send_page_view'].indexOf(prop) === -1){
            event_obj[prop] = ga_config_obj[prop];
        }
    }

    if (window.engagement && typeof engagement.getData === 'function'){
        let engagement_data = engagement.getData();
        event_obj.total_clicks = engagement_data.total_clicks || 0;
        event_obj.engagement_clicks = engagement_data.engagement_clicks || 0;
        event_obj.time_on_page = parseInt((engagement_data.time_on_page || 0)/1000);
        event_obj.scroll_depth = engagement_data.scroll_depth || 0;
        event_obj.attention = engagement_data.attention || 0;
        event_obj.content_progress = engagement_data.content_progress || 0;
    }
    else {
        event_obj.time_on_page = parseInt(gti(config.pageStartTime)/1000);
    }

    event_obj = trimObject(event_obj, true, true, props_to_exclude); //removing zeroes and empties to prevent "empty" values from taking up 25-dimension quota per event

    if (ga4_event_name.length > 40){
        console.warn('event name '+ga4_event_name+' is too long');
    }

    if (ga4_event_name === 'page_view'){
        props_sent_during_view = [];
    }
    props_only_needed_once.forEach(function(prop){
        if (event_obj[prop] && props_sent_during_view.indexOf(prop) === -1){
            props_sent_during_view.push(prop);
        }
    });

    let event_obj_properties_count = getPropertiesCount(event_obj);

    if (event_obj_properties_count > event_property_count_threshold){
        for (let i=0; i<props_only_needed_once.length; i++){
            if (props_sent_during_view.indexOf(props_only_needed_once[i]) > -1){
                delete event_obj[props_only_needed_once[i]];
                event_obj_properties_count--;

                // if (config.isTesting || config.isDev){
                //     console.warn('Deleted property '+props_only_needed_once[i]+' from event '+ga4_event_name);
                // }

                if (event_obj_properties_count <= event_property_count_threshold){
                    break;
                }
            }
        }
    }

    event_obj_properties_count = getPropertiesCount(event_obj);

    //delete less priority dimensions if still over
    if (event_obj_properties_count > event_property_count_threshold){
        ['event_index', 'timestamp', 'total_clicks', 'engagement_clicks', 'content_progress', 'scroll_depth'].forEach(function(prop){
            if (typeof event_obj[prop] !== 'undefined' && event_obj_properties_count > event_property_count_threshold){
                delete event_obj[prop];

                event_obj_properties_count = getPropertiesCount(event_obj);
            }
        });
    }

    if (event_obj_properties_count > event_property_count_threshold){
        console.warn('event object for '+ga4_event_name+' has '+event_obj_properties_count+' properties, more than the allowed '+event_property_count_threshold);
    }

    for (let prop in event_obj){
        if (prop.length > 40){
            console.warn('event property '+prop+' for event '+ga4_event_name+' is '+prop.length+' chars long, more than the allowed 40');
        }

        let too_long_warn = 'event property value '+event_obj[prop]+' for event property `'+ga4_event_name+'`.`'+prop+'` is '+(event_obj[prop]+'').length+' chars long, more than the allowed ';

        //https://support.google.com/analytics/answer/9267744?hl=en
        if ((event_obj[prop]+'').length > 100){
            // the page_title parameter must be 300 characters or fewer
            // the page_referrer parameter must be 420 characters or fewer
            // the page_location parameters must be 1,000 characters or fewer

            if (prop === 'page_title'){
                if (event_obj[prop].indexOf(' | ') > -1 && event_obj[prop].length > 300){
                    event_obj[prop] = event_obj[prop].split(' | ')[0];
                }

                if (event_obj[prop].length > 320){
                    console.warn(too_long_warn+'320');
                    event_obj[prop] = event_obj[prop].slice(0, 316)+'...';
                }
            }
            else if (prop === 'page_location'){
                if (event_obj[prop].length > 1000){ //really unlikely to exceed this
                    console.warn(too_long_warn+'1000');
                }
            }
            else if (prop === 'page_referrer'){
                if (event_obj[prop].length > 420){
                    console.warn(too_long_warn+'420');
                }
            }
            else if (isUrl(event_obj[prop])){
                event_obj[prop] = event_obj[prop].split('?')[0];
            }

            if (['page_location', 'page_referrer', 'page_title'].indexOf(prop) === -1 && typeof event_obj[prop] === 'string' && event_obj[prop].length > 100){
                console.warn(too_long_warn+'100');
                event_obj[prop] = event_obj[prop].slice(0, 96)+'...';
            }
        }
    }

    if (config.isClassy && !config.is_classy_studio){
        if (ga4_category === 'classy' && ga4_event_name === 'submit'){
            delete event_obj.event_category;
            delete event_obj.event_value;

            let amount = event_obj.event_label
            delete event_obj.event_label;

            return GAEvent('begin_checkout', Object.assign({
                value: numberSniffer(amount),
                items: [get_ecommerce_item()],
                ei: 1
            }, event_obj));
        }

        if (ga4_event_name === 'page_view'){
            //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
            GAEvent("view_item", Object.assign({
                currency: 'USD',
                value: '1.00',
                items: [get_ecommerce_item()]
            }, event_obj));
        }
        else if (ga4_event_name === 'purchase'){
            if (Array.isArray(event_obj.items) && event_obj.items.length && event_obj.items[0].item_category){
                let frequency = 'frequency:',
                    category = event_obj.items[0].item_category;

                if ((category+'').indexOf(frequency) === 0){
                    GAEvent("donation_"+category.replace(frequency, ''), {
                        el: event_obj.transaction_id,
                        ev: event_obj.value
                    });
                }
            }
        }
    }

    //TODO https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
    //TODO https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
    //TODO https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search

    else if (['display','click'].includes(ga4_event_name) && ['overlay', 'sticky footer', 'pushdown', 'takeover', 'signups'].includes(ga4_category)){
        let donation_link;

        if (ga4_category === 'overlay' || ga4_category === 'signups'){
            donation_link = $qs('a', $id('modal')); //CAUTION: will just catch first link

            if (!donation_link && ga4_category === 'signups'){
                // form starter?
            }
        }
        else if (ga4_category === 'sticky footer'){
            donation_link = $qs('a', $qs('.sticky-footer')); //CAUTION: will just catch first link ... AND need to grab just active
        }
        else if (ga4_category === 'pushdown'){
            donation_link = $qs('a', $qs('.pushdown')); //CAUTION: will just catch first link ... AND need to grab just active
        }

        if (donation_link && donation_link.href
            && (donation_link.href.indexOf('/donate') > -1 || donation_link.href.indexOf('https://donate.') === 0)
        ){
            let item_id,
                not_set = '(not set)';

            if (donation_link.href.indexOf('/give/') > -1){
                item_id = donation_link.href.split('/give/')[1].split('/')[0];
            }

            //https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_promotion
            GAEvent(ga4_event_name === 'display' ? 'view_promotion' : 'select_promotion', Object.assign({
                items: [
                    {
                        promotion_id: donation_link.id || not_set,
                        promotion_name: stripNonUTF(donation_link.innerText) || not_set,
                        creative_name: donation_link.className || not_set,
                        creative_slot: ga4_category,
                        item_id: item_id || 'evergreen',
                        item_name: donation_link.href,
                        affiliation: "EDF",
                        item_brand: "EDF",
                        price: 1,
                        quantity: 1
                    }
                ]
            }, event_obj));
        }
    }

    /*
    so far engagement_interaction includes:
    + video_views
    + slideshow click events
    + mailto clicks
    + file / download clicks
    + donation link clicks
    + action link clicks
    + form submissions
    + jumpout link clicks
    + cta carb button clicks
    + expert email clicks
    + contact email clicks
     */
    if (engagement_interaction){
        GAEvent('engagement_interaction', {
            ec: ga4_category,
            at: ga4_event_name+(!!event_obj.action_type?': '+event_obj.action_type:''),
            af: event_obj.action_focus || null,
            el: event_obj.event_label || null,
            dno: true
        });
    }

    GA4Ready(ga4_event_name, function(){
        _gtag_set();

        if (config.ga4_stream.length){
            for (let i=0; i<config.ga4_stream.length; i++){
                //https://developers.google.com/analytics/devguides/collection/ga4/page-view?hl=en#page-parameters
                window.gtag('event', ga4_event_name, {
                    ...event_obj,
                    send_to: 'G-'+config.ga4_stream[i]
                });
            }
        }
        else if (typeof window.gtag === 'function') {
            window.gtag('event', ga4_event_name, {...event_obj});
        }

        ga4_events.push({event: ga4_event_name,...event_obj});
    });
}

export function userTiming(cat, title, time) {
    //https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings
    GA4Ready(function(){
        //setting timeout to prevent any from sneaking in before page_view
        setTimeout(function(){
            GAEvent('timing_complete', {
                //NOTE: this is removing the timing category property
                ec: cat,
                el: title,
                ev: time
            });
        }, gti(config.pageStartTime) > 1000 * 10 ? 0 : 2000);
    });
}

export function virtualPage(path, title, block_ga4) {
    title = title || document.title;

    path = removeEPs(path || location.href);
    path = trimProtocol(path).replace(config.HN, '');

    let new_ref = removeEPs(config.current_page),
        search = location.search,
        hash = location.hash,
        url = config.LN + '//' + config.HN + path + (search || '')+hash,
        sanitized_url = removeEPs(url),
        nav_type = config.isInClassyEmbed ? -1 : 7,
        nav_type_value = config.isInClassyEmbed ? 'classy_embed' : 'virtual';

    ut.setPage({
        nav_type: nav_type,
        nav_type_value: nav_type_value,
        page_href: location.href,
        'loc.referrer': config.current_page,
        'loc.path': path,
        'loc.search': search.replace('?', ''),
        'loc.hash': hash.replace('#', '')
    });

    let data = {
        page_referrer: new_ref,
        page_title: title.split(' | ')[0],
        page_location: sanitized_url,
        page_path: path,
        nav_type: nav_type_value
    };

    _gtag_set(data);

    if (!block_ga4){
        GAEvent('page_view', data);
    }

    push({
        event: 'virtual_pageview',
        title: title,
        path: path,
        url: url,
        referrer: new_ref
    });

    config.docReferrer = new_ref;
    config.current_page = url;
}

export function decorateGA4Link(link, return_just_the_value){
    let id = 'hidden_link_'+gti(),
        domains = config.ga4_linker_domains,
        original_link = link,
        href;

    for (let i=0; i<domains.length; i++){
        if (domains[i] !== config.HNUpper){
            href = 'https://www.'+domains[i];
            break;
        }
    }

    if (!href || !window.gtag || isMacSafari){
        return link;
    }

    appendHTML({
        a: $name('body'),
        elem: 'a',
        id: id,
        href: href,
        "data-dnh": true
    });

    let faux_link = $id(id);
    addEvent(faux_link, ['mouseover', 'keydown', 'click', 'focus', 'keyup', 'change', 'blur', 'mouseout'], function(e){
        e.preventDefault();
        return false;
    });

    forceChange(faux_link);

    let decorated_link = faux_link.href,
        _gl = null;

    link = stripParam('_gl', link);

    if (decorated_link){
        _gl = gup('_gl', decorated_link);

        if (_gl){
            link += qrySep(link)+'_gl='+_gl;
        }
    }

    if (return_just_the_value){
        return _gl;
    }

    //fallback to original link if the new link
    if (link === href){
        link = original_link;
    }

    return link;
}

export function decorateForm(form){
    let mode = '_gl',
        input = $qs('#'+mode, form);

    if (input){
        input.remove();
    }

    let decoration = decorateGA4Link(form.action, true);

    if (decoration){
        appendHTML({
            a: form,
            elem: 'input',
            type: 'hidden',
            id: mode,
            name: mode,
            value: decoration
        });
    }
}

export function getTitle(){
    return (ut.getPage('page_title') || document.title).split(' | ')[0];
}

export function getUrl(){
    return removeEPs(config.current_page.replace(config.PN, getPath()), params_to_keep_for_ga).toLowerCase();
}

export function getPath(){
    let path = config.PN,
        status = ut.getPage('page_status'),
        title = getTitle(),
        title_lower = title.toLowerCase(),
        weird_adestra = config.isAdestra && config.PN.toLowerCase().indexOf('environmental-defense-fund') === -1,
        normal_adestra = title.toLowerCase().replace(/ /g, '-').replace(/'/g, '').replace(/"/g, '');

    if (config.is50th) {
        path = '/' + config.HS.replace('#', '');
    }
    else if (weird_adestra && config.PN.indexOf('/k/') > -1){
        if (config.PN.indexOf('/k/') > -1){
            path = '/k/' + normal_adestra;
        }
        else if (config.PN.indexOf('/q/') > -1){
            path = '/q/' + normal_adestra;
        }
    }

    if (status && status !== '200') {
        not_found = status;
        path = '/' + status + '.html';
    }
    else if (config.isWordpress && (title_lower === 'not found' || title_lower.indexOf('nothing found') > -1)) {
        not_found = 404;
        path = (config.isBiz ? '' : '/' + config.PN.split('/')[1]) + '/404.html';
    }

    return path.toLowerCase();
}

export function getBrand(){
    //TODO leverage domains and Classy accounts, but for now:
    let brand = 'EDF';
    if (config.isMCAFC4){
        brand = 'CAMA';
    }
    else if (config.isMCAF){
        brand = 'MCAF';
    }
    else if (config.isMRD){
        brand = 'MRD';
    }
    else if (config.isC4){
        brand = 'EDFACTION';
    }

    return brand;
}

export function describeStarterFormGAItem(form, placement, id){
    let donation_starter_form_label = 'donation starter form';
    return [
        {
            promotion_id: '/donate',
            promotion_name: donation_starter_form_label,
            creative_name: stripNonUTF(getHeadline(form.closest('.ConvioDonation') || form.closest('.convio-donation') || form.closest('.c-block__inner'))) || '(not set)',
            creative_slot: placement || donation_starter_form_label,
            item_id: id || 'evergreen',
            item_name: removeEPs(form.action),
            affiliation: getBrand(),
            item_brand: getBrand(),
            price: 1,
            quantity: 1
        }
    ];
}

export {
    ga4_events,
    ga_config_obj,
    ga_user_obj,
    ga_consent_obj,
    last_ga_config_obj,
    last_ga_user_obj
}