import {onLCP, getLCP, onFID, getFID, onCLS, getCLS, getFCP, onFCP, getTTFB, onINP, getINP} from 'web-vitals';
import * as ut from "./ut";
import {_gtag_set} from "./ga";
import { push } from "./datalayer";
import {addLoadEvent} from "./events";

// https://github.com/GoogleChrome/web-vitals#import-web-vitals-from-npm

// (function () {
//     var script = document.createElement('script');
//     script.src = 'https://unpkg.com/web-vitals@3/dist/web-vitals.iife.js';
//     script.onload = function () {
//         // When loading `web-vitals` using a classic script, all the public
//         // methods can be found on the `webVitals` global namespace.
//         webVitals.onCLS(console.log);
//         webVitals.onFID(console.log);
//         webVitals.onLCP(console.log);
//     };
//     document.head.appendChild(script);
// })();

let pushed = [];

function handleCWV(measure){
    let name = measure.name.toLowerCase(),
        value = Math.round(measure.value), //cls fix decimal
        obj = {};

    ut.setPage(name, value);

    obj[name] = value;
    _gtag_set(obj);

    if (pushed.indexOf(name) === -1){
        pushed.push(name);
        push({event: 'web_vital', ec: name, ev: value});
    }
}

function get(){
    getTTFB(handleCWV);
    getFCP(handleCWV);
    getCLS(handleCWV);
    getFID(handleCWV);
    getLCP(handleCWV);
    getINP(handleCWV);
}

get();

onFCP(handleCWV);
onCLS(handleCWV);
onFID(handleCWV);
onLCP(handleCWV);
onINP(handleCWV); //https://web.dev/inp/ https://web.dev/blog/inp-cwv-march-12?hl=en

addLoadEvent(get);
setTimeout(get, 15000);

window.EDFWebVitals = {
    getTTFB: getTTFB,
    getFCP: getFCP,
    getCLS: getCLS,
    getFID: getFID,
    getLCP: getLCP,
    getINP: getINP,
    onFCP: onFCP,
    onCLS: onCLS,
    onFID: onFID,
    onLCP: onLCP,
    onINP: onINP
};

//
// export function webVitals(){
//     try {
//         new PerformanceObserver(function(entryList){
//             entryList.getEntries().forEach(console.log)
//
//             // if (!entry.hadRecentInput) {
//             //     cumulativeLayoutShift += entry.value;
//             // }
//
//         }).observe({ type: "layout-shift", buffered: true });
//
//         new PerformanceObserver(function(entryList){
//             entryList.getEntries().forEach(console.log)
//         }).observe({ type: "largest-contentful-paint", buffered: true });
//
//         new PerformanceObserver(function(entryList){
//             entryList.getEntries().forEach(console.log)
//         }).observe({ type: "first-input", buffered: true });
//     }
//     catch(e) { /* API is not supported */ }
// }

// export function injectionDetection(allowed_hosts) {
//     allowed_hosts = allowed_hosts || ['dv8.edf.org'];
//
//     let hosts = {},
//         host,
//         unknowns = [],
//         requests = getNetworkReqs();
//
//     if (!requests){
//         return;
//     }
//
//     for (let i = 0; i < requests.length; i++) {
//         host = (new URL(requests[i].name)).hostname;
//
//         if (!(host in hosts)) {
//             hosts[host] = [];
//         }
//
//         hosts[host].push(requests[i]);
//     }
//
//     for (host in hosts){
//         if (allowed_hosts.indexOf(host) === -1){
//             unknowns.push(host);
//         }
//     }
//
//     if (config.isDev){
//         console.log(unknowns);
//         console.log(hosts);
//     }
// }