import { config } from './config';
import {createCookie, LS_G, LS_S, readCookie} from "./storage";
import * as ut from "./ut";
import {_ef, gti, isInt} from "./helpers";
import {ajaxReq} from "./ajax_req";

let has_flags = false,
    getting_flags = false,
    done = false,
    user_flags = null;

function isTimeFlag() {
    return user_flags && user_flags.t && !isNaN(user_flags.t);
}

function canParse(item) {
    try {
        user_flags = JSON.parse(item);
    } catch (e) {}

    return isTimeFlag();
}

function stash(obj) {
    obj.t = parseInt(gti() / 1000);

    dataLayer[0].user.convio_flags = config.getUser = {
        sustainer: obj.e,
        donor: obj.d,
        persona: obj.p,
        state: obj.s,
        zip: obj.z,
        mobile_commons: obj.mo,
        hpc: obj.h,
        lta: obj.l,
        c4: obj.c4,
        c4_opt: obj.c4d,
        accepts_email: obj.a
    };

    try {
        if (!config.known) {
            window.is_ecopartner = obj.e;
            window.is_donor = obj.d;
            window.is_persona = obj.p;
            config.convio_user.s = obj.s || '';
            config.convio_user.z = obj.z || '';
            config.convio_user.mo = obj.mo || '';
            //config.convio_user.dmo = obj.dmo || '';
            config.convio_user.c4 = obj.c4 || '';
            config.convio_user.c4d = obj.c4_opt || '';
            config.convio_user.ae = obj.a ? 'true' : '';

            if (obj.h && parseInt(config._HPC) !== obj.h) {
                config._HPC = obj.h;
                createCookie('lta', obj.h);
                LS_S('lta', obj.h);
                ut.setUser('hpc', obj.h);
            }

            window._LTA = obj.l || window._LTA || 0;
        }

        let str = JSON.stringify(obj);

        LS_S('flags', str);
        createCookie('flags', str);

        has_flags = true;
    } catch (e) {}

    dataLayer[0].user.convio = config.convio_user || {};

    done = true;
}

function refresh(cb) {
    cb = cb || _ef;

    if (!isInt(config.user_id)) {
        return cb(true);
    }

    user_flags = null;
    getting_flags = true;

    ajaxReq(config.utility_path + 'convio/cons_id/' + config.user_id, function(err, json) {
        getting_flags = false;

        if (!err) {
            user_flags = json;
            stash(user_flags);
        }

        done = true;
        cb(err, getUser);
    });
}

if (config.DNT){
    //do nothing
}
else if (config.known) {
    stash({
        e: is_ecopartner,
        d: is_donor,
        p: is_persona,
        s: config.convio_user.s,
        z: config.convio_user.z,
        h: config._HPC === '' ? null : config._HPC,
        l: _LTA === '' ? null : _LTA,
        mo: config.convio_user.mo,
        //dmo: config.convio_user.dmo,
        c4: config.convio_user.c4,
        c4d: config.convio_user.c4d
    });
}
else {
    let found = false;
    if (LS_G('flags')) {
        found = canParse(LS_G('flags'));
    }

    if (!found && readCookie('flags')) {
        found = canParse(readCookie('flags'));
    }

    if (found && gti() / 1000 - user_flags.t < 60 * 60 * 24 * 5) {
        stash(user_flags);
    }
    else if (!isInt(config.user_id) && !config.isGDPR) {
        refresh();
    }
    else {
        done = true;
    }
}

function whenDone(callback){
    if (getting_flags || !done) {
        let waiting = setInterval(function() {
            if (done) {
                clearInterval(waiting);
                callback(has_flags);
            }
        }, 10);
    }
    else {
        callback(has_flags);
    }
}

export {
    refresh,
    whenDone
}