import {loadScript} from "./load_assets"; //EXTREME CAUTION: leave this unused import here for now so that it doesn't break legacy classy build process. There is some weird import ordering quirk that this shims into place.
import {constants} from "./constants";
import {gup, viewport} from "./helpers";

//https://github.com/d4tocchini/customevent-polyfill
if (!window.CustomEvent){
    let CustomEvent;

    CustomEvent = function(event, params) {
        let evt;

        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined
        };

        evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    };

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
}

function ReplaceWithPolyfill() {
    'use-strict'; // For safari, and IE > 10

    let parent = this.parentNode,
        i = arguments.length,
        currentNode;

    if (!parent) return;

    if (!i) // if there are no arguments
        parent.removeChild(this);
    
    while (i--) { // i-- decrements i and returns the value of i before the decrement
        currentNode = arguments[i];

        if (typeof currentNode !== 'object'){
            currentNode = this.ownerDocument.createTextNode(currentNode);
        }
        else if (currentNode.parentNode){
            currentNode.parentNode.removeChild(currentNode);
        }

        // the value of "i" below is after the decrement
        if (!i) // if currentNode is the first argument (currentNode === arguments[0])
            parent.replaceChild(currentNode, this);
        else // if currentNode isn't the first
            parent.insertBefore(currentNode, this.previousSibling);
    }
}

if (!Element.prototype.matches) {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;

                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        let el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

if (!Element.prototype.replaceWith) {
    Element.prototype.replaceWith = ReplaceWithPolyfill;
}

if (!CharacterData.prototype.replaceWith) {
    CharacterData.prototype.replaceWith = ReplaceWithPolyfill;
}

if (!DocumentType.prototype.replaceWith) {
    DocumentType.prototype.replaceWith = ReplaceWithPolyfill;
}

if (!document.querySelectorAll) {
    document.querySelectorAll = document.body.querySelectorAll = Object.querySelectorAll = function querySelectorAllPolyfill(r, c, i, j, a) {let d = document, s = d.createStyleSheet();a = d.all;c = [];r = r.replace(/\[for\b/gi, '[htmlFor').split(',');for (i = r.length; i--; ) {s.addRule(r[i], 'k:v');for (j = a.length; j--; ) {a[j].currentStyle.k && c.push(a[j]);}s.removeRule(0);}return c;};
}

//https://github.com/kaimallea/isMobile
let IS_MOBILE = (function () {var a={};var f=/iPhone/i,h=/iPod/i,i=/iPad/i,r=/\biOS-universal(?:.+)Mac\b/i,g=/\bAndroid(?:.+)Mobile\b/i,j=/Android/i,c=/(?:SD4930UR|\bSilk(?:.+)Mobile\b)/i,d=/Silk/i,b=/Windows Phone/i,k=/\bWindows(?:.+)ARM\b/i,m=/BlackBerry/i,n=/BB10/i,o=/Opera Mini/i,p=/\b(CriOS|Chrome)(?:.+)Mobile/i,q=/Mobile(?:.+)Firefox\b/i;function s(l){return function($){return $.test(l)}}function e(l){var $=(l=l||("undefined"!=typeof navigator?navigator.userAgent:"")).split("[FBAN");void 0!==$[1]&&(l=$[0]),void 0!==($=l.split("Twitter"))[1]&&(l=$[0]);var a=s(l),e={apple:{phone:a(f)&&!a(b),ipod:a(h),tablet:!a(f)&&a(i)&&!a(b),universal:a(r),device:(a(f)||a(h)||a(i))&&!a(b)},amazon:{phone:a(c),tablet:!a(c)&&a(d),device:a(c)||a(d)},android:{phone:!a(b)&&a(c)||!a(b)&&a(g),tablet:!a(b)&&!a(c)&&!a(g)&&(a(d)||a(j)),device:!a(b)&&(a(c)||a(d)||a(g)||a(j))||a(/\bokhttp\b/i)},windows:{phone:a(b),tablet:a(k),device:a(b)||a(k)},other:{blackberry:a(m),blackberry10:a(n),opera:a(o),firefox:a(q),chrome:a(p),device:a(m)||a(n)||a(o)||a(q)||a(p)},any:!1,phone:!1,tablet:!1};return e.any=e.apple.universal||e.apple.device||e.android.device||e.windows.device||e.other.device,e.phone=e.apple.phone||e.android.phone||e.windows.phone,e.tablet=e.apple.tablet||e.android.tablet||e.windows.tablet,e}a=e();return a})();

function isURLSupported(){
    let supported;
    try {
        let str = location.href,
            url = new URL(str);
        supported = url.hostname === location.hostname;
    } catch(e){}

    return supported;
}

function isIE() {
    return navigator.appName === 'Microsoft Internet Explorer'
        || constants.userAgent.indexOf("MSIE ") > 0
        || !!(constants.userAgent.match(/Trident/) || constants.userAgent.match(/rv:11/));
}

function placeholder() {
    return 'placeholder' in document.createElement('input');
}

function isIOS() {
    return constants.userAgent.match(/iPhone|iPad|iPod/i);
}

function is_touch_device() {
    return 'ontouchstart' in window
        || (window.DocumentTouch && document instanceof DocumentTouch)
        || (navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
}

function isTablet(){
    return IS_MOBILE.tablet;
}

function isMobile() {
    return IS_MOBILE.phone;
}

function isDesktop(){
    return !isTablet() && !isMobile();
}

export function getDeviceType(){
    if (isTablet()){
        return 'tablet';
    }

    if (isMobile()){
        return 'mobile';
    }

    return 'desktop';
}

//https://github.com/fingerprintjs/BotD
// const botdPromise = import('https://openfpcdn.io/botd/v1').then((Botd) => Botd.load())
// // Get detection results when you need them.
// botdPromise
//     .then((botd) => botd.detect())
//     .then((result) => console.log(result))
//     .catch((error) => console.error(error))

function botCheck(){
    //https://raw.githubusercontent.com/monperrus/crawler-user-agents/master/crawler-user-agents.json
    let bots = ['googlebot', 'adsbot', 'mediapartners-google', 'bingbot', 'duckduckbot', 'slurp', 'baiduspider', 'yandexbot', 'facebot', 'facebookexternalhit', 'ia_archiver', 'xenu', 'mediapartners', 'ahrefs', 'petalbot', 'linkedinbot', 'msnbot'],
        isBot = false;

    for (let b = 0; b < bots.length; b++) {
        if (constants.userAgent.toLowerCase().indexOf(bots[b]) > -1) {
            isBot = true;
        }
    }

    if (viewport().width < 2 || viewport().height < 2){
        isBot = true;
    }

    return isBot;
}

//https://github.com/tysonmatanich/GetDevicePixelRatio/blob/master/getDevicePixelRatio.js
function getDevicePixelRatio() {
    let ratio = 1,
        screen = window.screen,
        devicePixelRatio = window.devicePixelRatio,
        systemXDPI = screen.systemXDPI,
        logicalXDPI = screen.logicalXDPI;

    if (devicePixelRatio !== undefined) {
        ratio = devicePixelRatio;
    }
    else if (systemXDPI !== undefined
        && logicalXDPI !== undefined
        && systemXDPI > logicalXDPI
    ) {
        ratio = systemXDPI / logicalXDPI;
    }

    return ratio;
}

function supportsBeacon(){
    return navigator && typeof navigator.sendBeacon === 'function' && typeof navigator.sendBeacon.bind === 'function';
}

let isAppleMobile = isIOS,
    isBot = botCheck() || gup('pretend_to_be_a_bot'),
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    isMacSafari = isSafari && (navigator.vendor+'').toLowerCase().indexOf('apple') > -1,
    isBrave = false;

if (navigator.brave && typeof navigator.brave.isBrave === 'function'){
    try {
        isBrave = window.navigator.brave.isBrave.name === "isBrave";
    } catch(e){}
}

export {
    isBot,
    isAppleMobile,
    isSafari,
    isMacSafari,
    isIOS,
    isIE,
    isBrave,
    placeholder,
    isURLSupported,
    is_touch_device,
    botCheck,
    getDevicePixelRatio,
    supportsBeacon,
    isTablet,
    isDesktop,
    isMobile,
    IS_MOBILE
}