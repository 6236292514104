import {config} from "./config";
import { dataLayer, push } from "./datalayer";
import {_gtag_set} from './ga';
import * as ut from './ut';
import {ajaxReq} from "./ajax_req";
import {SS_enabled, SS_G, SS_R, SS_S, wuphf} from "./storage";
import {_ef} from "./helpers";
import {fireCustomEvent} from "./events";

let key = '_intlv',
    label = 'is_international',
    stashed = wuphf(key),
    is_international = null;

function handleInternationalSignal(){
    config.isIntl = is_international;
    dataLayer[0][label] = is_international;
    push({event: 'international_visitor_signal', is_international: is_international});
    ut.setSess(label, is_international);
    fireCustomEvent('international_visitor_signal', {is_international: is_international});
}

function lookup(cb){
    cb = cb || _ef;

    let international_handled = false;

    if (stashed !== null){
        stashed = parseInt(stashed);

        if ([0,1].indexOf(stashed) > -1){
            is_international = !!stashed;
        }
        else {
            wuphf(key, null, -1);
        }
    }

    if (is_international !== null){
        international_handled = true;
        handleInternationalSignal();
    }

    if (!SS_enabled){
        if (typeof config.isIntl === 'boolean'){
            is_international = config.isIntl;
            handleInternationalSignal();
        }

        return cb();
    }

    stashed = SS_G('iplookup', true);

    if (stashed && typeof stashed === 'object' && stashed.service){
        setSession(stashed);
        return cb(stashed)
    }

    ajaxReq({
        url: config.utility_path+'ip',
        withCredentials: true,
        timeout: 5000,
        ignore_errors: true
    }, function(err, json) {
        if (!err && json) {
            if (json.country_code){
                is_international = (json.country_code+'').trim().toLowerCase() !== 'us';
            }

            if (json.country){
                let country = (json.country+'').trim().toLowerCase().replace(/ /g,'');
                is_international = country.indexOf('america') === -1 && country.indexOf('unitedstates') === -1 && country !== 'us' && country !== 'usa';
            }

            ut.setUser('edf_uuid', json.uuid);

            setSession(json);
            SS_S('iplookup', json, null, true);
            cb(json);
        }
        else {
            cb();
        }

        if (is_international !== null){
            wuphf(key, is_international ? 1 : 0);

            if (!international_handled || ut.getSess(label) !== is_international){
                handleInternationalSignal();
            }
        }
        else if (typeof config.isIntl === 'boolean'){
            is_international = config.isIntl;
            handleInternationalSignal();
        }
    });
}

function setSession(json){
    if (json.service === 'ip-api' || json.service === 'ipgeolocation_io'){
        ut.setSess({
            network_domain: json.domain || null,
            network_provider: json.isp || null,
            network_organization: json.org || null,
            network_as: json.as || null,
            network_asn: json.asname || null,

            network_continent_code: json.continent_code,
            network_continent: json.continent,
            network_country_code: json.country_code,
            network_country: json.country,
            network_region_code: json.region_code,
            network_region: json.region,
            network_latitude: json.latitude,
            network_longitude: json.longitude,
            network_timezone: json.timezone,
            network_timezone_offset: json.offset,
            network_currency: json.currency,
            ip: json.ip
        });
    }
}

function setDimension(json){
    if ((json.type+'').toLowerCase().indexOf('(not set)') > -1){
        json.type = null;
    }

    let dimensions = {
        network_category: json.type,
        network_domain: json.domain,
        network_provider: json.name,
        connection_type: config.connectionLabel || null,
    }

    _gtag_set(dimensions);
    ut.setSess(dimensions);
}

function enrich(){
    if (SS_enabled && (config.isEDF || config.isBiz)) {
        let key = 'ipmeta',
            stashed = SS_G(key, true);

        if (stashed && typeof stashed === 'object' && stashed.domain) {
            return setDimension(stashed);
        }

        SS_R(key);

        ajaxReq({
            method: 'POST',
            url: 'https://ipmeta.io/api/enrich',
            data: {
                h: config.HN,
                k: '2fab581b0d9781f825dda22374d408dcefed67cbf90e3d167701814b89da5da8'
            },
            timing: true
        }, function(err, json){
            if (!err && json && json.domain) {
                setDimension(json);
                SS_S(key, json, null, true);
            }
        });
    }
}

export {
    lookup,
    enrich,
    is_international
}