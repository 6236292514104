import { config } from "./config";
import { dataLayer } from "./datalayer";
import {gti} from "./helpers";
import {readCookie, wuphf} from './storage';
import * as ut from "./ut";

let user_ids = ['temp_id', 'edf_uuid', 'cons_id', 'adestra_contact_id', 'lytics_uid', 'p2a_advocate_id', 'classy_member_id', 'classy_supporter_id', 'cid', 'ga_client_id', 'drupal_uid'],
    user_id_prefixes = ['', 'u', 'c', 'a', 'l', 'p', 'm', 's', 'g', 'v', 'd'];

export function getUserIds(){
    let ids = [];

    user_ids.forEach(function(id, index){
        let value = ut.getUser(id);
        if (value){
            if (id === 'ga_client_id' && ids.indexOf('g'+value) > -1){
                //GA client id between UA and GA4 appears to be the same
            }
            else {
                ids.push(user_id_prefixes[index]+value);
            }
        }
    });

    return ids.join('|') || null;
}

export function setUser() {
    let temp_id = 't'+gti()+'-'+parseInt(Math.random()*1000),
        existing_temp_id = wuphf('temp_id'),
        drupal_uid = config.drupalUID,
        contact_id = wuphf('acid'),
        classy_member_id = wuphf('cmlid'),
        cons_id = wuphf('cons_id'),
        lytics_user_id = readCookie('seerid'); //jstag.config.cookie

    if (existing_temp_id && existing_temp_id.toString().indexOf('bject') === -1){
        temp_id = existing_temp_id;
    }

    wuphf('temp_id', temp_id, 10000);
    ut.setUser('temp_id', temp_id);

    if (contact_id && !isNaN(contact_id) && !ut.getUser('adestra_contact_id')){
        ut.setUser('adestra_contact_id', parseInt(contact_id));
    }

    if (classy_member_id && !isNaN(classy_member_id) && !ut.getUser('classy_member_id')){
        ut.setUser('classy_member_id', parseInt(classy_member_id));
    }

    if (cons_id && !isNaN(cons_id) && !ut.getUser('cons_id')){
        ut.setUser('cons_id', parseInt(cons_id));
    }

    if (drupal_uid && !isNaN(drupal_uid)){
        ut.setUser('drupal_uid', parseInt(drupal_uid));
    }

    if (lytics_user_id){
        ut.setUser('lytics_uid', lytics_user_id);
    }

    let ids = getUserIds();
    config.user_id = ids;
    dataLayer[0].user_id = ids;
}