import { SS_S, SS_G } from './storage';
import { ajaxReq } from './ajax_req';
import { loadScript } from './load_assets';

export function SS_jsonWrapper(label, url, cb) {
    function stash(json) {
        let json_str;

        try {
            json_str = JSON.stringify(json);
        } catch(e){}

        if (json_str){
            cb(json);
            SS_S(label, json_str);
        }
    }

    function handle(err, json){
        if (err) {
            return cb(null, {error: 1});
        }

        stash(json);
    }

    let valid = false;
    if (SS_G(label)) {
        try {
            valid = JSON.parse(SS_G(label));
        } catch (e) {}
    }

    if (valid) {
        return cb(valid);
    }

    if (url.indexOf('callback=?') === url.length - 10) {
        url = url.replace('callback=?', '');

        loadScript({
            url: url.slice(0, url.length - 1),
            format: 'jsonp'
        }, handle);
    }
    else {
        ajaxReq({
            url: url,
            cache: url.indexOf('/handles') > -1 //no sense applying gti parameter to /handles requests
        }, handle);
    }
}