import {config} from "./config";
import {enc, gti, gup, isDownload, qrySep, trim} from "./helpers";
import {isLinkLink} from "./functions";
import {forAll} from "./dom";
import {readCookie} from "./storage";
import * as ut from "./ut";

let domains = '',
    custom_transfer = 'custom_transfer';

export function decorateLink(link){
    let hash = '',
        temp,
        added = 0;

    if (!link) {
        return null;
    }

    link = trim(link);

    if (!isLinkLink(link) || link.indexOf(config.UTD+'/') > -1) {
        return null;
    }

    if (link.indexOf('#') !== -1) {
        hash = '#' + link.split('#').pop();
        link = link.split('#')[0];
    }

    if (link.indexOf('mailto:') === 0 || isDownload(link)) {
        return null;
    }

    let sid = ut.getSess('id'),
        pid = ut.getPage('id'),
        utm_id = ut.getSess('utm_id');

    if (sid){
        link += qrySep(link) + 'ut_sid='+sid;
    }

    if (pid){
        link += qrySep(link) + 'ut_pid='+pid;
    }

    if (utm_id){
        link += qrySep(link) + 'ut_id='+utm_id;
    }

    for (let k = 0; k < config.custom.length; k++) {
        temp = config.custom[k];
        if (temp !== 'addl_info' && readCookie(temp)) {
            added = 1;
            link += qrySep(link) + temp + '=' + enc(readCookie(temp));
        }
    }

    temp = readCookie('source');
    if (temp) {
        added = 1;
        link += qrySep(link) + 'custom_source=' + enc(temp);
    }

    temp = readCookie('sub_source');
    if (temp) {
        added = 1;
        link += qrySep(link) + 'custom_sub_source=' + enc(temp);
    }

    if (added) {
        link += qrySep(link) + custom_transfer + '=' + gti();
    }

    return link+hash;
}

export function custom_transfers(){
    let redirects = config.php_gift_redirects,
        gift_selectors = '';

    if (!domains) {
        for (let i = 0; i < config.ga4_linker_domains.length; i++) {
            if (config.ga4_linker_domains[i] !== config.HNUpper) {
                domains += (!!domains ? ', ' : '') + 'a[href*="' + config.ga4_linker_domains[i] + '"]';
            }
        }
    }

    for (let i = 0; i < redirects.length; i++) {
        gift_selectors += ', a[href$="' + redirects[i] + '"], a[href$="' + redirects[i] + '/"], a[href*="' + redirects[i] + '?"], a[href*="' + redirects[i] + '/?"]';
    }

    let elements_to_add_class_to = [];
    forAll(domains + gift_selectors, function(e){
        if (e.classList.contains(custom_transfer)){
            return;
        }

        let original_url = e.href,
            link = decorateLink(original_url);

        if (!link){
            return;
        }

        e.href = link;

        if (link.indexOf(custom_transfer) > -1){
            elements_to_add_class_to.push(e)
            e.dataset.transfer = link;
            e.dataset.original_url = original_url;
        }

        requestAnimationFrame(function(){
            elements_to_add_class_to.forEach(function(e){
                e.classList.add(custom_transfer);
            });
        });
    });

    setInterval(function() {
        forAll('.'+custom_transfer, function(e){
            let url = e.href,
                current_gup_id = gup(custom_transfer, url),
                new_gup_id = gti()+'';

            if (current_gup_id){
                e.href = url.replace(current_gup_id, new_gup_id);
            }
            else if (e.dataset.transfer) {
                url = e.dataset.transfer;
                current_gup_id = gup(custom_transfer, url);

                if (current_gup_id){
                    e.href = url.replace(current_gup_id, new_gup_id);
                    e.dataset.transfer = e.href;
                }
            }
        });
    }, 1000*10);
}