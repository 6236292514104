import { config } from "./config";
import { SS_jsonWrapper } from "./ss_json_wrapper";
import { loadStyle } from "./load_assets";

export function mobilizeEvents(tag){
    let org_ids = tag.dataset.org,
        valid_ids = true,
        org_count = 0;

    if (!org_ids){
        return;
    }

    org_ids = org_ids.replace(/ /g, '');

    org_ids.split('-').forEach(function(org_id){
        if (isNaN(org_id)){
            valid_ids = false;
        }

        org_count++;
    });

    if (!valid_ids){
        return;
    }

    if (tag.dataset.css){
        loadStyle(tag.dataset.css);
    }

    SS_jsonWrapper('mobilize'+org_ids, config.utility_path+'mobilize/events/'+org_ids, function(json){
        let list = '<p><strong>There are currently no events.</strong></p>',
            classname_prefix = 'mobilize_event';

        if (json && Array.isArray(json) && json.length){
            list = '';

            for (let n = 0; n < json.length; n++){
                let event = json[n],
                    event_title = event.title,
                    event_url = event.browser_url,
                    event_time = Array.isArray(event.timeslots) && event.timeslots.length > 0 ? new Date(event.timeslots[0]).toLocaleDateString() : null;

                list += `
                    <li class="${classname_prefix} ${classname_prefix}--org_${event.org_id} ${classname_prefix}--${event.id} ${classname_prefix}--type_${event.event_type.toLowerCase()}">
                        <div class="${classname_prefix}--image_container">
                            <a href="${event_url}">
                            <img src="${event.featured_image_url}" class="${classname_prefix}--image" title="${event_title}" alt="${event_title}" />
                        </a>
                        </div>
                        <div class="${classname_prefix}--event_container">
                            <h4 class="${classname_prefix}--title">
                                <a href="${event_url}">${event_title}</a>
                            </h4>
                            <p class="${classname_prefix}--org_name" style="color: #909090;margin-top: -10px;${(org_count>1&&event.org?'':'display:none;')}">${event.org||''}</p>
                            <p class="${classname_prefix}--description">${(event.description+'').replace(/\\n\\n/g, '<br><br>')}</p>
                            ${(!!event_time?'<p><strong>Next event time: '+event_time+'</strong></p>':'')}
                            <p class="${classname_prefix}-link">
                                <a href="${event_url}">${(tag.dataset.link||'See available event times and locations')} &raquo;</a>
                            </p>
                        </div>
                    </li>
                `;
            }
        }

        tag.innerHTML = list;
    });
}