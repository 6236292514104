import { isInt, isEmail, isEmailValid } from './helpers';
import { NR } from './new_relic';

function isPublishable(obj) {
    // FORMAT 2019-12-31 or 2020-01-01 16:00:00
    function isValid(d) {
        let date = new Date(d),
            isDate = date instanceof Date && !isNaN(date.valueOf());

        if (!isDate) {
            NR.error('Invalid Pub Date: '+d+' Source: '+obj);
        }

        return isDate;
    }

    if (obj.publish && isValid(obj.publish)) {
        if (new Date() < new Date(obj.publish)) {
            return false;
        }
    }

    if (obj.unpublish && isValid(obj.unpublish)) {
        if (new Date() >= new Date(obj.unpublish)) {
            return false;
        }
    }

    return true;
}

function isUrl(url){
    return typeof url === 'string'
        && (new RegExp(
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i)).test(url);
}

function ifIsInt(value){
    return isInt(value) ? value : null
}

export {
    isEmail,
    isEmailValid,
    isPublishable,
    isUrl,
    ifIsInt
}