import {config} from './config';
import {gup} from "./helpers";
import {loadScript} from "./load_assets";
import {addEvent} from "./events";

function runStats(cb) {
    let stats = new Stats();
    stats.showPanel(1);

    document.body.appendChild(stats.domElement);

    function animate() {
        stats.begin();
        cb();
        stats.end();
        requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
}

function jsStats() {
    if (window.Stats) {
        return runStats();
    }

    loadScript(config.cdnjs+'stats.js/r16/Stats.min.js', runStats);
}

export function perf(){
    if (config.isTesting) {
        //TODO add plugins to actually trigger boomerang to init
        //https://github.com/akamai/boomerang
        //https://github.com/akamai/boomerang/tree/master
        if (gup('boomerang') === 'true') {
            addEvent(document, 'onBoomerangLoaded', function() {
                console.log('onBoomerangLoaded');

                if (!window.BOOMR){
                    return;
                }

                window.BOOMR.init({
                    beacon_url: config.utility_path + 'beacon',
                    ResourceTiming: {
                        enabled: true,
                        clearOnBeacon: true
                    }
                });

                window.BOOMR.subscribe('before_beacon', function(event) {
                    console.log(event)
                });

                window.BOOMR.subscribe("xhr_send", (event) => { //https://github.com/akamai/boomerang/issues/338#issuecomment-1329537291
                    console.log(event);
                })

                window.BOOMR.t_end = new Date().getTime();
            });

            //https://github.com/akamai/boomerang#32-adding-it-via-an-iframepreload
            loadScript('https://cdn.jsdelivr.net/npm/boomerangjs/boomerang.min.js');
        }

        if (gup('jsstats') === 'true') {
            jsStats();
        }
    }
}

//https://github.com/Zizzamia/perfume.js