//https://github.com/lytics/js-snippets
//https://docs.lytics.com/docs/lytics-javascript-tag
//https://docs.lytics.com/docs/personalize-the-messaging-of-your-website-based-on-audience
//https://github.com/lytics/quickstart
//https://github.com/lytics/lytics-js
//https://github.com/lytics/jstag-proxy
//https://github.com/lytics/pathforajs-examples/blob/master/examples%2Finline-content-mod%2Finline-content-mode.js
//https://github.com/lytics/pathforajs
//https://github.com/lytics/pathforajs-examples

//TODO detect when lytics does not load

import {config} from "./config";
import * as ut from "./ut";
import {$id, gti, trimObject} from "./helpers";
import {acceptsCookies} from "./storage";
import {isBrave} from "./client_detection";
import {addReadyEvent, listenForEvent} from "./events";
import {_LS, injectCSS} from "./load_assets";
import {GAEvent} from "./ga";
import {$qs, appendHTML, waitForElement} from "./dom";

let key = null,
    data = {},
    options = {},
    sent = [],
    modules = [],
    pathfora_exists = false;
    //stream = 'web_'+config.HN;

let motivation_prompt = {
    id: 'motivation_for_giving_prompt_modal',
    type: 'Form',
    layout: 'modal',
    headline: 'What motivated your gift today?',
    okMessage: 'Submit',
    theme: 'custom',
    formStates: {
        success: {
            headline: 'Thank you!',
            msg: 'We have received your response.',
            delay: 0
        },
        error: {
            headline: 'Uh-oh!',
            msg: 'There was an issue submitting your response. Could you please try again?',
            delay: 10
        }
    },
    formElements: [
        {
            type: 'radio-group',
            label: 'Select your reason',
            name: 'motivation',
            required: true,
            values: [
                {
                    label: 'My concern about the impacts of climate change',
                    value: 'climate'
                },
                {
                    label: 'My desire to protect nature and wildlife',
                    value: 'nature'
                },
                {
                    label: 'All of the above',
                    value: 'all_the_above'
                },
                {
                    label: 'None of the above / other',
                    value: 'none_or_other'
                }
            ]
        }
    ]
}

export function setProperty(name, value){
    if (value !== null && typeof value !== 'undefined'){
        data[name] = value;
    }
}

export function configure(){
    if (config.DNT || !acceptsCookies()){
        return;
    }

    if (config.isEDF || config.isMCAF || (config.isMethaneSat && !config.isMethaneSatData)){
        key = config.isDev ? '4ecf70726b1dac840b50b7b0d8f8f614' : 'e8b79965d9046d68aefa9cfa095ef883';
    }

    if (!key){
        return;
    }

    injectCSS('[data-pftrigger], [data-pfrecommend]{ display: none; }');

    _LS('pf');

    addReadyEvent(function(){
        if (config.isLocal && $id('test_lytics_pf')){
            pf({
                ...motivation_prompt,
                layout: 'inline',
                positionSelector: '#test_lytics_pf'
            });
        }
    });

    listenForEvent('ajaxReqSubmission', function(e){
        if (config.log){
            console.log(e);
        }

        if (!e || !e.data){
            return;
        }

        // {
        //     "type": "ajaxReqSubmission",
        //     "data": {
        //         "method": "POST",
        //         "url": "https://test.utility.edf.org/apps/surveys/4",
        //         "format": "json",
        //         "body_format": "json",
        //         "withCredentials": true,
        //         "data": {
        //         "surveyId": "4",
        //             "data": {
        //             "question1": "none_of_the_time",
        //                 "question2": [
        //                 "extreme_weather_is_in_the_air"
        //             ],
        //                 "question3": [
        //                 "being_more_sustainable_in_my_life",
        //                 "raising_awareness_about_the_dangers_of_climate_change"
        //             ]
        //         },
        //         "results": {
        //             "I feel anxious about global warming:": {
        //                 "none_of_the_time": "None of the time"
        //             },
        //             "My climate anxiety spikes when (choose all that apply):": {
        //                 "extreme_weather_is_in_the_air": "Extreme weather is in the news"
        //             },
        //             "When you feel climate anxiety, what helps alleviate it? (Choose all that apply.)": {
        //                 "being_more_sustainable_in_my_life": "Being more sustainable in my own life (for example, eliminating single-use plastic in your day-to-day)",
        //                     "raising_awareness_about_the_dangers_of_climate_change": "Raising awareness about the dangers of climate change"
        //             }
        //         },
        //         "user": "t1695757231698-151|udefedd08-4254-4108-b9e6-317a2b02a75e|a37844|l716847e1-534c-4b02-8d27-a29b5cbb9fb6|m17741984|s38752216|g327546874.1695757232.",
        //             "v": "2"
        //     }
        // }
        // }

        let obj = e.data;
        if (obj.method === 'POST' && obj.url.indexOf('utility.edf.org/apps/surveys/') > -1 && obj.data && obj.data.surveyId){
            obj = obj.data;

            let survey_id = (obj.surveyId || obj.survey_id)+'';
            if (survey_id === '4'){ //https://stage.edf.org/have-climate-anxiety-take-our-survey-and-take-action
                let lytics_obj = {
                    form_name: 'custom_survey_'+survey_id
                }

                let index = 0;
                for (let question in obj.data){
                    index++;

                    let name = question,
                        counter = 0;

                    for (let long_name in obj.results){
                        counter++;

                        if (counter === index){
                            name = long_name.toLowerCase().replace(/:/g, '').replace(/ /g, '_');
                        }
                    }

                    lytics_obj['formdata_cs'+survey_id+'_'+name] = obj.data[question];
                }

                event(trimObject(lytics_obj));
            }
        }
    });

    !function(){"use strict";var o=window.jstag||(window.jstag={}),r=[];function n(e){o[e]=function(){for(var n=arguments.length,t=new Array(n),i=0;i<n;i++)t[i]=arguments[i];r.push([e,t])}}n("send"),n("mock"),n("identify"),n("pageView"),n("unblock"),n("getid"),n("setid"),n("loadEntity"),n("getEntity"),n("on"),n("once"),n("call"),o.loadScript=function(n,t,i){var e=document.createElement("script");e.async=!0,e.src=n,e.onload=t,e.onerror=i;var o=document.getElementsByTagName("script")[0],r=o&&o.parentNode||document.head||document.body,c=o||r.lastChild;return null!=c?r.insertBefore(e,c):r.appendChild(e),this},o.init=function n(t){return this.config=t,this.loadScript(t.src,function(){if(o.init===n)throw new Error("Load error!");o.init(o.config),function(){for(var n=0;n<r.length;n++){var t=r[n][0],i=r[n][1];o[t].apply(o,i)}r=void 0}()}),this}}();

    //https://docs.lytics.com/docs/lytics-javascript-tag#configuration
    options = {
        src: isBrave ? config.utility_path+'ly.js' : 'https://c.lytics.io/api/tag/'+key+'/latest.min.js',

        blocked: false, //TODO why timing out?

        loadid: true, //By default, Lytics will store a first and third-party cookie representing a user. The loadid flag allows Lytics to use the third-party cookie if available, thus pulling cross-domain behavior into a single, unified user profile.

        //stream: stream, //do not use stream as that introduces overhead and headaches with mappings

        send: {
            pageViewOnFirst: false //CAUTION: setting false can still send an initial hit, same properties as pageview, just without `_e:pv`, if manual pageview trigger is delayed
        },

        pageAnalysis: {
            dataLayerPull: {
                disabled: true
            },
            performPageAnalysis: true
        },

        debug: config.isDev
    }

    // if (config.isClassy){
    //     options.customGlobals = {
    //         disabled: false,
    //         globals: ['edf_dataLayer', 'dataLayer'] //also set in Lytics UI. at the moment, these are causing issues with Snowflake event sync
    //     }
    // }

    let user = ut.getUser(),
        session = ut.getSess(),
        page = ut.getPage(),
        obj = {
            ga_client_id: user.ga_client_id || null,
            edf_uuid: user.edf_uuid || null,
            device_id: user.device_id || null,
            adestra_contact_id: user.adestra_contact_id || null,
            utm_id: session.utm_id || null,
            page_id: page.id || null,
            session_id: session.id || null
        };

    obj = trimObject(obj);

    data = {
        ...data,
        ...obj
    };

    jstag.init(options);

    pageview();

    jstag.getid(function(id){
        ut.setUser('lytics_uid', id);

        if (config.isDev || config. isTesting){
            console.log('View Lytics profile at: https://app.lytics.com/entity/user/_uids/'+id+'?aid=571'+(config.isProd?'7':'6')+'&redirect=%2Faudiences&table=user&view=details');
        }
    });

    jstag.call('entityReady', function(profile){
        if (config.useDev){
            console.log(profile); //profile.data.user.segments //TODO send to GA and Utility
        }

        if (profile && profile.data && profile.data.user && Array.isArray(profile.data.user.segments)){
            let segments = profile.data.user.segments;
            segments.splice(segments.indexOf('all')-1);

            if (segments.length){
                ut.setUser('lytics_segments', segments.join(','));
            }
        }
    });
}

export function pageview(event){
    if (!key || !window.jstag){
        return;
    }

    event = trimObject(event || {});
    event = {...data, ...event};

    jstag.pageView(event, function(e){
        if (config.useDev){
            console.log(e);
        }

        sent.push({...event});
    });
}

export function event(event){
    if (!key || !window.jstag){
        return;
    }

    event = trimObject(event);
    event = {...data, ...event};

    jstag.send(event, function(e){
        if (config.useDev){
            console.log(e);
        }

        sent.push({...event});
    });
}

export function getSegments(){
    if (!key || !window.jstag){
        return;
    }

    return jstag.getSegments();
}

export function getCachedAudiences(){
    let audiences = [];

    try {
        audiences = JSON.parse(localStorage.lytics_segments);
    } catch (error) {
        console.error(error);
    }

    return audiences;
}

export function personalize(){
    //     --url 'https://api.lytics.io/api/me/fieldname/fieldval?segments=true&meta=true&download=false' \
}

export function set(obj){
    if (!key || !window.jstag){
        return;
    }

    obj = trimObject(obj);
    data = {...data, ...obj};

    jstag.send(data);
}

export function setEmail(email){
    if (!key || !window.jstag){
        return;
    }

    data = {
        ...data,
        email: email
    };

    jstag.send(data);
}

export function pathforaReady(cb){
    if (pathfora_exists){
        return cb();
    }

    let wait = setInterval(function() {
        if (!!window.jstag && !!window.pathfora){
            pathfora_exists = true;
            window.pathfora.enableGA = false; //https://lytics.github.io/pathforadocs/tracking/
            clearInterval(wait);
            cb();
        }
    }, 1);
}

function getGAEventDataFromPayload(payload, data_as_label){
    return {
        ec: 'pathfora',
        el: !!payload.data && !!data_as_label ? JSON.stringify(payload.data) : payload.config.headline,
        at: payload.config.type+'_'+payload.config.layout,
        af: payload.config.id+' ('+(payload.config.variant || 1)+')'
    }
}

export function pf(opts, cb){
    cb = cb || function(){};

    if (['Message', 'Form', 'Subscription', 'Gate'].indexOf(opts.type) === -1){
        return cb(true);
    }

    if (['modal', 'slideout', 'bar', 'button', 'inline'].indexOf(opts.layout) === -1){
        return cb(true);
    }

    //hideAfter controls the module display, not the confirmation displays
    opts.hideAfter = 0;

    pathforaReady(function(){
        let options = {
            id: opts.layout+'-'+gti(),
            ...opts
        };

        //https://lytics.github.io/pathforadocs/callbacks/
        ['onInit', 'onLoad', 'confirmAction', 'cancelAction', 'closeAction', 'onClick', 'onModalClose'].forEach(function(action){
            if (!options[action]){
                if (action === 'onClick' && options.layout !== 'bar'){
                    return;
                }

                if (action === 'confirmAction'){
                    options[action] = {
                        name: 'custom_'+action,
                        waitForAsyncResponse: true,
                        callback: function(event, payload, cb) {
                            if (config.isDev){
                                console.log(event); //can be modalConfirm for modal layout
                                console.log(payload);
                            }

                            GAEvent('pf_submit', getGAEventDataFromPayload(payload, !!opts.data_as_label));

                            if (typeof cb === 'function'){
                                cb(true); //allows for cb(false), which would bail
                            }
                        }
                    }
                }
                else if (action === 'cancelAction'){
                    options[action] = {
                        name: 'custom_'+action,
                        callback: function(event, payload) {
                            if (config.isDev){
                                console.log(event);
                                console.log(payload);
                            }

                            GAEvent('pf_cancel', getGAEventDataFromPayload(payload));
                        }
                    }
                }
                else {
                    options[action] = function(event, payload) {
                        if (config.isDev){
                            console.log(event);
                            console.log(payload);
                        }

                        let data = getGAEventDataFromPayload(payload);

                        if (event === 'widgetLoaded'){
                            GAEvent('pf_display', data);
                        }
                        else if (event === 'modalClosed'){
                            GAEvent('pf_close', data);
                        }
                        else if (event === 'onClick'){
                            GAEvent('pf_click', data);
                        }
                        else if (event === 'modalConfirm'){
                            //handled by confirmAction?
                        }
                    }
                }
            }
        });

        delete options.type;

        //CAUTION forms can generate a lot of events //TODO find some way to prevent useless hover and focus
        //temp1.removeEventListener('mouseenter', getEventListeners(temp1).mouseenter[0].listener)
        let module = new window.pathfora[opts.type](options);

        window.pathfora.initializeWidgets([module]);

        modules.push(module);

        cb(null, module);
    });
}

export function testModal(){
    pf(motivation_prompt);
}

export function testSlideout(){
    pf({
        ...motivation_prompt,
        id: motivation_prompt.id+'_slideout',
        layout: 'slideout',
        position: 'bottom-right'
    });
}

export function testInline(){
    let inline = $qs('article .c-block__inner') //https://dv8.edf.org/issue/natural-climate-solutions
        || $qs('.thank-you_header_top')         //https://donate.edf.org/give/346177/?chums=testing#!/donation/thank-you
        || null;

    if (!inline){
        return;
    }

    appendHTML({
        a: inline,
        elem: 'div',
        id: 'test_inline_pf'
    });

    pf({
        ...motivation_prompt,
        id: motivation_prompt.id+'_inline',
        layout: 'inline',
        positionSelector: '#test_inline_pf'
    });
}

if (config.isTesting && config.isClassy && config.PN.indexOf('346177') > -1){
    waitForElement('.thank-you_header_top', testInline);
}

export {
    data,
    options,
    sent,
    modules
}