import {_ef} from "./helpers";

let Sentry = {
        captureMessage: _ef,
        captureException: _ef,
        withScope: _ef,
        configureScope: _ef,
        getCurrentScope: _ef,
        addBreadcrumb: _ef,
        setSentryTag: _ef,
        setSentryTags: _ef,
        sentryMessage: _ef,
        setSentryUser: _ef,
        fake: true,
        Severity: {
            Info: 'Info'
        }
    },
    SentryEvents = 0,
    SentryExceptions = [];

let errorCount = 0,
    unhandledrejectionCount = 0;

export function setSentryTag(key, val){}
export function setSentryTags(obj){}
export function setSentryUser(obj){}
export function sentryMessage(message, obj){}

export {
    errorCount,
    unhandledrejectionCount,
    Sentry,
    SentryEvents,
    SentryExceptions
}