import { config } from "./config";
import { push } from "./datalayer";
import {createCookie, isCookie, readCookie} from "./storage";
import { GAEvent } from "./ga";
import * as ut from "./ut";
import {$qs} from "./dom";
import {formDataToJSON} from "./forms";

//custom signup, mailchimp, action, donation events for lytics

export function on_signup(opts){
    let signup_obj = {
            event: 'signup',
            at: opts.dupe ? 'existing' : 'new',
            contact_id: opts.contact_id,
            response: opts.response,
            form_options: opts.form_options || null
        },
        addl_info = ut.getSess('addl_info') || isCookie('addl_info');

    if (!opts.form){
        opts.form = {}; //shouldn't be the case, but just in case
    }

    if (opts.form.jquery){
        opts.form = opts.form[0]; //de-jquery it
    }

    if (opts.form.className){
        signup_obj.form_class = opts.form.className;
    }

    let custom_string21 = $qs('.custom_string21', opts.form);
    if (custom_string21){
        addl_info = custom_string21.value;
    }

    GAEvent('sign_up', {
        ly: false,
        ec: 'signups',
        at: (opts.dupe ? 'existing' : 'new')+' contact',
        af: opts.full,
        el: opts.form_options ? JSON.stringify(opts.form_options) : signup_obj.form_class
    });

    triggerEvent('EDANSignup', {...opts});

    push(signup_obj);

    let gtm_obj = {
        event: opts.dupe ? 'dupeSignup' : 'newSignup',
        contact_id: opts.contact_id,
        label: opts.short,
        full_label: opts.full,
        conversion_pg: opts.conv || ut.getSess('conversion_pg') || config.current_page,
        conversion_pg_query: ut.getSess('utm_id') || isCookie('conversion_pg_query') || isCookie('utm_i'),
        addl_info: addl_info
    };

    //move dcm tags here

    config.custom.forEach(function(prop){
        if (!gtm_obj[prop]){
            gtm_obj[prop] = isCookie(prop) || ut.getSess(prop);
        }
    })

    push(gtm_obj);

    if (window.engagement && opts.engage) {
        engagement.onSignup();
    }

    ut.incPage('goals');
}

export function on_mailchimp_signup(opts){
    if (opts.list_id){
        let subscribed = (readCookie('mcs') || opts.list_id).split(':');
        if (subscribed.indexOf(opts.list_id) > -1) {
            subscribed.push(opts.list_id);
        }

        createCookie('mcs', subscribed.join(':'), 10000);
    }

    if (!opts.form){
        opts.form = {};
    }

    if (opts.form.jquery){
        opts.form = opts.form[0]; //de-jquery it
    }

    opts.message = opts.message || null;
    opts.data = opts.data || null;

    if (!opts.data){
        opts.data = formDataToJSON(opts.form);
    }

    opts.data.ADDL = opts.data.ADDL || opts.data.addl_info || opts.data.custom_string21 || null;

    let email = opts.email || opts.data.email;
    if (email){
        ut.setUser('email', email);
    }

    GAEvent('mailchimp_signup', {
        dno: true,
        ec: 'mailchimp',
        el: opts.el || opts.list || null,
        at: opts.at || opts.message.toLowerCase(),
        af: opts.af || opts.list_id || null,
        ei: 1
    });

    push({
        event: 'mailchimpSignup',
        id: opts.list_id || null,
        list_id: opts.list_id || null,
        list: opts.list || null,
        addl_info: opts.data.ADDL,
        mc_message: opts.message,
        name: opts.name || null,
        had_email: typeof opts.had_email === 'boolean' ? opts.had_email : true
    });
}