function StringBuffer() {
    this.buffer = [];
}

StringBuffer.prototype.append = function append(string) {
    this.buffer.push(string);
    return this;
};

StringBuffer.prototype.toString = function toString() {
    return this.buffer.join('');
};

function Utf8EncodeEnumerator(input) {
    this._input = input;
    this._index = -1;
    this._buffer = [];
}

Utf8EncodeEnumerator.prototype = {
    current: Number.NaN,

    moveNext: function() {
        if (this._buffer.length > 0) {
            this.current = this._buffer.shift();
            return true;
        }

        if (this._index >= this._input.length - 1) {
            this.current = Number.NaN;
            return false;
        }

        let charCode = this._input.charCodeAt(++this._index);

        if (charCode === 13 && this._input.charCodeAt(this._index + 1) === 10) {
            charCode = 10;
            this._index += 2;
        }

        if (charCode < 128) {
            this.current = charCode;
        }
        else if (charCode > 127 && charCode < 2048) {
            this.current = (charCode >> 6) | 192;
            this._buffer.push((charCode & 63) | 128);
        }
        else {
            this.current = (charCode >> 12) | 224;
            this._buffer.push(((charCode >> 6) & 63) | 128);
            this._buffer.push((charCode & 63) | 128);
        }

        return true;
    }
};

let codex = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

let encode = function(input) {
    let output = new StringBuffer(),
        enumerator = new Utf8EncodeEnumerator(input);

    while (enumerator.moveNext()) {
        let chr1 = enumerator.current;

        enumerator.moveNext();
        let chr2 = enumerator.current;

        enumerator.moveNext();
        let chr3 = enumerator.current,
            enc1 = chr1 >> 2,
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4),
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6),
            enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        }
        else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output.append(
            codex.charAt(enc1) +
            codex.charAt(enc2) +
            codex.charAt(enc3) +
            codex.charAt(enc4)
        );
    }

    return output.toString();
};

let decode = function(s) {
    let e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;

    for(i=0;i<64;i++){e[codex.charAt(i)]=i;}

    for(x=0;x<L;x++){
        c=e[s.charAt(x)];b=(b<<6)+c;l+=6;

        while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
    }

    return r;
}

export {
    encode,
    decode
}