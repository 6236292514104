//CAUTION: need to be very careful that form ids used are whitelisted for embed mode

import {config} from "./config";
import {loadScript} from "./load_assets";
import {$qs, forAll, getDomPath} from "./dom";
import {addEvent} from "./events";
import * as ut from "./ut";
import {gti, gup} from "./helpers";
import {GAEvent} from "./ga";
import {observeElement} from "./observe_element";

//https://support.classy.org/s/article/embedded-giving#modal
//https://support.classy.org/s/article/more-embedded-form-customizations
//https://support.classy.org/s/article/classy-data-layer#embedded

let sdk_loading = false,
    sdk_loaded = false,
    request_time,
    load_time;

//thank you page at https://donate.edf.org/give/495716/#!/donation/checkout?eg=true&egp=ty

export function onAjaxResponse(err, a, url, response){
    if (config.verbose){
        console.log(err, a, url, response);
    }
}

export function embedSelector(selector){
    let shadowroot = null;

    if ($qs('.egsandbox') && $qs('.egsandbox').shadowRoot){
        shadowroot = $qs('.egsandbox').shadowRoot
    }

    if (!shadowroot){
        return null;
    }

    return $qs(selector, shadowroot);
}

export function embed(opts, cb){
    cb = cb || function(){};

    let selector = opts.selector || '.donate',
        mode = (opts.mode || 'modal').toLowerCase(),
        id = opts.id || opts.form_id || opts.campaign_id || '495716',
        allowedParams = ['amount', 'frequency', 'recurring', 'addl_info', 'conversion_pg', 'egrn'];

    //CAUTION classy strips these away and generates a new virtual_pageview event
    let urlParams = {
            c_src2: ut.getSess('id')
        },
        utm_id = ut.getSess('utm_id');

    if (utm_id){
        urlParams.c_src = utm_id;
    }

    if (config.isTesting || config.isDev){
        urlParams.chums = 'testing';
    }

    allowedParams.forEach(function(param){
        if (opts[param]){
            urlParams[param] = opts[param];
        }
    });

    if (opts.recurringNudge){
        urlParams.egrn = true;
    }

    window.egProps = window.egProps || {
        campaigns: []
    }

    let embed_options = {
        [mode]: {
            lazyLoad: typeof opts.lazyLoad === 'boolean' ? opts.lazyLoad : false,
            urlParams: urlParams,
            elementSelector: selector
        }
    };

    if (Array.isArray(opts.nudgeTrays)){
        embed_options.nudgeTrays = opts.nudgeTrays;
    }
    else if (opts.nudgeTrays === true){
        embed_options.nudgeTrays = [{
            title: 'Make an impact',
            content: 'Complete your donation to make our mission possible.',
            ctaLabel: 'Donate now',
            ctaColor: '#a1e214',
            triggerEvent: 'eg:donation:incomplete',
        }];
    }

    window.egProps.campaigns.push({
        campaignId: id,
        customDomain: opts.domain || 'donate.edf.org',
        donation: embed_options,

        //these show in the sdk source code, but I don't think they are exposed.
        // onModalClose: function(e){
        //     console.log(e)
        // },
        // onDonationComplete: function(e){
        //     console.log(e)
        // },
        // onModalOpen: function(e){
        //     console.log(e)
        // },
        // onModalButtonClick: function(e){
        //     console.log(e)
        // },
        // onNudgeTrayClose: function(e){
        //     console.log(e)
        // },
        // onNudgeTrayCtaClick: function(e){
        //     console.log(e)
        // },
        // onNudgeTrayCloseClick: function(e){
        //     console.log(e)
        // },
        // onDonationProps: function(e){
        //     console.log(e)
        // },
        // onError: function(e){
        //     console.error(e)
        // },
        // onClose: function(e){
        //     console.log(e)
        // },
        // onCtaClick: function(e){
        //     console.log(e)
        // },
        // onCloseClick: function(e){
        //     console.log(e)
        // }

        //disableCookieDialog: //TODO
    });

    if (!sdk_loaded && !sdk_loading){
        sdk_loading = true;

        if (!opts.lazyLoad){
            request_time = gti();
        }

        loadScript({url: 'https://sdk.classy.org/embedded-giving.js', defer: true}, function(){
            sdk_loaded = true;

            if (embed_options.nudgeTrays){
                let nudge_tray_class = '.egnudgetray',
                    classy_embed_nudge = 'classy_embed_nudge',
                    classy_embed_nudge_tray = classy_embed_nudge+'_tray',
                    nudge_opened = false;

                //TODO pivot to dedicated cssstylesheet: https://developer.mozilla.org/en-US/docs/Web/API/Web_components/Using_shadow_DOM
                let waitForShadow = setInterval(function(){
                    if (!$qs('.egsandbox').shadowRoot || !embedSelector(nudge_tray_class+'_ctabtn')){
                        return;
                    }

                    clearInterval(waitForShadow);

                    observeElement(embedSelector(nudge_tray_class), function(visible){
                        if (visible){
                            GAEvent(classy_embed_nudge, {
                                ec: 'classy',
                                at: 'open',
                                af: classy_embed_nudge_tray
                            });

                            if (!nudge_opened){
                                nudge_opened = true;

                                GAEvent("view_item", {
                                    currency: 'USD',
                                    value: '1.00',
                                    items: [{
                                        item_name: 'Nudge tray for form '+id,
                                        item_id: id,
                                        price: 1,
                                        item_brand: 'Environmental Defense Fund',
                                        quantity: 1
                                    }]
                                });
                            }
                        }
                    });

                    let nudgetray = embedSelector(nudge_tray_class);
                    if (nudgetray){
                        nudgetray.style.borderColor = '#cccccc';
                        nudgetray.style.borderWidth = '1px';
                        nudgetray.style.borderStyle = 'solid';
                    }

                    let cta_btn = embedSelector(nudge_tray_class+'_ctabtn');
                    if (cta_btn){
                        cta_btn.style.color = 'black';
                    }

                    addEvent(cta_btn, 'click', function(e){
                        GAEvent(classy_embed_nudge, {
                            ec: 'classy',
                            at: 'click',
                            af: classy_embed_nudge_tray
                        });
                    });

                    addEvent(embedSelector(nudge_tray_class+'_closebtn'), 'click', function(e){
                        GAEvent(classy_embed_nudge, {
                            ec: 'classy',
                            at: 'close',
                            af: classy_embed_nudge_tray
                        });
                    });
                }, 10);
            }
        });
    }

    let throttle = false,
        opened = false;

    if (mode === 'modal'){
        forAll(selector, function(e){
            e.classList.add('classy_embed_trigger');

            addEvent(e, 'click', function(e){
                if (opts.lazyLoad && !request_time){
                    request_time = gti();
                }

                if (!opened){
                    opened = true;

                    let faq = embedSelector('button.egiframe_faq_toggle');
                    if (faq){
                        faq.style.position = 'relative';
                        faq.style.left = '-9999px';
                    }

                    ['modal_backdrop', 'modal_closebtn'].forEach(function(className){
                        addEvent(embedSelector('.'+className), 'click', function(){
                            GAEvent('classy_embed_close', {
                                ec: 'classy',
                                el: className,
                                at: 'click',
                                af: id
                            });
                        });
                    });

                    addEvent('keydown', function(evt){
                        if (evt.keyCode === 27 || evt.key === 'Escape') {
                            let modal = embedSelector('.modal');
                            if (modal && modal.classList.contains('is_open')){
                                //CAUTION: going to generate a close event signaling a close with .modal_closebtn
                                embedSelector('.modal_closebtn').click();
                            }
                        }
                    });
                }

                let selections = {};

                if (e.target && e.target.href){
                    let link = e.target.href,
                        amount = gup('amount', link),
                        frequency = gup('frequency', link),
                        starter_form = e.target.closest('form.starter-form');

                    //TODO ask Sam to keep links within donation starter forms and can then remove this forced selection
                    if (!starter_form){
                        starter_form = $qs('.donate_now_block form.starter-form')
                    }

                    if (amount){
                        selections.amount = amount;
                    }
                    else if (starter_form){
                        let input = $qs('.choice.current input', starter_form);
                        if (input){
                            if ($qs('.choice.current.other', starter_form)){
                                selections.other_amount = input.value;
                            }
                            else {
                                selections.amount = input.value;
                            }
                        }
                    }

                    if (frequency){
                        selections.frequency = frequency;
                    }
                    else if (starter_form){
                        let input = $qs('[name="frequency"]', starter_form);
                        if (input){
                            selections.frequency = input.value;
                        }
                    }
                }

                if (!throttle){
                    throttle = true;
                    setTimeout(function(){
                        throttle = false;
                    }, 250);

                    GAEvent('classy_embed_open', {
                        ec: 'classy',
                        el: getDomPath(e.target).slice(2).join(' '),
                        at: 'click',
                        af: id
                    });

                    let message = {
                        ...selections,
                        tracking_data: {
                            ppid: ut.getPage('id'),
                            conversion_pg: config.HN+config.PN
                        }
                    };

                    if (utm_id){
                        message.tracking_data.utm_id = utm_id;
                    }

                    sendMessage(message);
                }
            });
        });
    }

    let subscribe = setInterval(function(){
        if (sdk_loaded){
            let waiting = setInterval(function(){
                if (!$qs('.egsandbox')){
                    return;
                }

                forAll($qs('.egsandbox').shadowRoot, 'iframe', function(e){
                    clearInterval(waiting);

                    if (e.dataset.subscribed === 'true'){
                        return;
                    }

                    if ((e.src+'').indexOf('https://donate') !== 0 || (e.src+'').indexOf('#!/donation/checkout') === -1){
                        return;
                    }

                    clearInterval(subscribe);

                    e.dataset.subscribed = 'true';

                    cb('subscribed');

                    addEvent('message', function(message){
                        let embedded_giving = 'embedded-giving',
                            msg = (typeof message.data === 'object' ? JSON.stringify(message.data) : message.data).trim();

                        if (config.verbose){
                            console.log('\x1b[36m%s\x1b[0m', typeof message.data === 'object' ? JSON.stringify(message.data) : message.data);
                            console.log('\x1b[36m%s\x1b[0m', msg);
                        }

                        if (!load_time && msg.indexOf(embedded_giving+':'+id+':donation:modal') === 0){
                            load_time = gti();

                            let duration = load_time-request_time;
                            userTiming('classy_embed_load', 'window.onLoad', duration);

                            if (config.isDev){
                                console.warn('Classy embed load time: '+duration);
                            }
                        }

                        //these aren't actually coming. Using custom events instead
                        // if (msg.indexOf(embedded_giving+':') === 0){
                        //     let event = msg.split(':');
                        //
                        //     //embedded-giving:nudge-tray:track-event:close
                        //     if (event[1] === 'nudge-tray' && event[2] === 'track-event'){
                        //         GAEvent('classy_embed_nudge', {
                        //             ec: 'classy',
                        //             at: event[3],
                        //             af: 'classy_embed_nudge_tray'
                        //         });
                        //     }
                        // }

                        cb(message);

                        //message.data can include PII
                        //embedded-giving:495716:donation:modal:state?currency=USD&recurring=0&amount=1&email=brian.chumney@gmail.com&first=Brian&last=Chumney
                        // GAEvent('track', {
                        //     ec: 'classy',
                        //     at: message.type,
                        //     af: message.data
                        // });
                    });
                });
            }, 100);
        }
    }, 100);
}

export function attach(){
    forAll('.classy-embed', function(elem){
        if (elem.dataset.attached){
            return;
        }

        let id = elem.id;

        if (!id){
            id = 'classy_embed_'+gti();
            elem.id = id;
        }

        let options = {
            selector:'#'+id,
            ...elem.dataset
        };

        elem.dataset.attached = 'true';

        embed(options);
    });
}

export function sendMessage(obj){
    let shadowroot = $qs('.egsandbox').shadowRoot;
    if (shadowroot){
        let iframe = shadowroot.querySelector('iframe');
        if (iframe){
            iframe.contentWindow.postMessage(JSON.stringify({
                type: 'embed',
                ...obj
            }), "*");
        }
    }
}