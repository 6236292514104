import {config} from "./config";
import {addEvent, fireEvent} from "./events";

export function watchForLocationChanges(relaxed, cb){
    if (typeof relaxed === 'function'){
        cb = relaxed;
        relaxed = false;
    }

    history.pushState = (function(f){
        return function pushState(){
            let ret = f.apply(this, arguments);
            fireEvent(['pushstate', 'locationchange']);
            return ret;
        }
    })(history.pushState);

    history.replaceState = (function(f){
        return function replaceState(){
            let ret = f.apply(this, arguments);
            fireEvent(['replacestate', 'locationchange']);
            return ret;
        }
    })(history.replaceState);

    addEvent('popstate',function(){
        fireEvent('locationchange');
    });

    let throttle;
    addEvent('locationchange', function(){
        clearTimeout(throttle);
        if (config.PN !== location.pathname || (relaxed && config.current_page !== location.href)) {
            throttle = setTimeout(cb, 1500);
        }
    });
}