import {addUnLoadEvent} from "./events";
import {GAEvent} from "./ga";

export function observeElement(el, opts, cb){
    let visible = false,
        total_view_time = 0,
        active_time,
        last_time;

    if (!el){
        return;
    }

    function trackTime(time, is_total){
        if (!opts.track_time){
            return;
        }

        GAEvent('in_view', {
            ec: opts.ec,
            el: (is_total ? 'total_' : '')+'time',
            at: opts.el,
            af: time
        });
    }

    if (typeof opts === 'function'){
        cb = opts;

        opts = {
            track_time: false,
        };
    }

    cb = cb || function(){};

    let handler = function(){
        let position = el.getBoundingClientRect();
        visible = position.top < window.innerHeight && position.bottom >= 0;

        cb(visible, window, handler);
    };

    if (typeof IntersectionObserver === 'function'){
        let observer = new IntersectionObserver(function(entry, observer){
            let time = new Date().getTime();

            visible = entry[0].isIntersecting || entry[0].intersectionRatio > 0;

            if (visible) {
                active_time = time;
            }
            else if (last_time) {
                total_view_time += time - last_time;

                if (active_time){
                    trackTime(time - active_time);
                    active_time = null;
                }
            }

            last_time = time;

            cb(visible, observer, entry);
        }, {root: null, threshold: 0});

        observer.observe(el);
    }
    else {
        handler();
        addEvent('scroll', handler);
    }

    if (opts.track_time){
        addUnLoadEvent(function(){
            GAEvent('viewport_time', {
                ec: opts.ec,
                el: 'total_time',
                at: opts.el,
                af: total_view_time
            });
        });
    }
}