import {config} from "./config";
import * as ut from "./ut";
import {GAEvent} from "./ga";
import {gti} from "./helpers";

export function on_page_unload(type){
    config.pageEndTime = config.pageEndTime || gti();

    let obj = {
        event: 'unload',
        at: type,
        tp: config.pageEndTime-config.pageStartTime
    };

    if (config.last_click && config.last_click.l){
        obj.lc = config.last_click.l;
        obj.last_click = {...config.last_click}
    }

    ut.push(obj);

    GAEvent('page_unload', {
        x: true,
        at: type,
        last_click: obj.lc || null,
        time_on_page: obj.tp
    });

    if (window.engagement){
        engagement.unLoad();
    }

    // if (typeof injectionDetection === 'function'){
    //     injectionDetection();
    // }
}